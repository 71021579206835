<h1 class="page-title">Alert/Tooltip/Message</h1>
<h2>Tooltips</h2>
<table class="table table-striped">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Values</th>
      <th>Comment</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>[placement]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'top'</b></li>
          <li><b>'right'</b></li>
          <li><b>'bottom'</b></li>
          <li><b>'left'</b></li>
        </ul>
      </td>
      <td>Required, no default value!</td>
    </tr>
    <tr>
      <td><b>[title]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td></td>
    </tr>
    <tr>
      <td><b>[content]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td>
        You can use directive instead of input for complex content with links, lists, etc.<br>
        Popover will show on click in this case, not on hover.
      </td>
    </tr>
    <tr>
      <td><b>#content</b></td>
      <td>directive</td>
      <td><pre [innerText]="'<article content>...</article>'"></pre></td>
      <td>
        You can use directive instead of input for complex content with links, lists, etc.<br>
        Popover will show on click in this case, not on hover.
      </td>
    </tr>
    <tr>
      <td><b>#element</b></td>
      <td>directive</td>
      <td><pre [innerText]="'<app-svg-icons element [name]=...></app-svg-icons>'"></pre></td>
      <td>
        Use with svg-icons component
      </td>
    </tr>
    <tr>
      <td colspan="4">Examples</td>
    </tr>
    <tr>
      <td colspan="2">
        Some click tooltip with link and title
          <app-popover [placement]="'top'" [title]="'Title'">
            <app-svg-icons element [name]="'info'"></app-svg-icons>
            <article content>
              <p>Some text and <a href="#">Some link</a>, an icon: <app-svg-icons [name]="'eye'"></app-svg-icons></p>
              <ul class="list-styled">
                <li>one</li>
                <li>two</li>
                <li>three</li>
              </ul>
            </article>
          </app-popover>
      </td>
      <td colspan="2">
        <code>&lt;app-popover [placement]="'top'" [title]="'Title'"&gt;<br>
          &lt;app-svg-icons element [name]="'info'"&gt;&lt;/app-svg-icons&gt;<br>
          &lt;article content&gt;<br>
          &lt;p&gt;Some text and &lt;a href="#"&gt;Some link&lt;/a&gt;, an icon: &lt;app-svg-icons [name]="'eye'"&gt;&lt;/app-svg-icons&gt;&lt;/p&gt;<br>
          &lt;ul class="list-styled"&gt;
          &lt;li&gt;one&lt;/li&gt;
          &lt;li&gt;two&lt;/li&gt;
          &lt;li&gt;three&lt;/li&gt;
          &lt;/ul&gt;<br>
          &lt;/article&gt;<br>
          &lt;/app-popover&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        Some tooltip to right on hover
          <app-popover [content]="'Some tooltip text'" [title]="'Some tooltip title'" [placement]="'right'">
            <app-svg-icons element [name]="'info'"></app-svg-icons>
          </app-popover>
      </td>
      <td colspan="2">
        <code>&lt;app-popover [content]="'Some tooltip text'" [title]="'Some tooltip title'" [placement]="'right'"&gt;<br>
          &lt;app-svg-icons element [name]="'info'"&gt;&lt;/app-svg-icons&gt;<br>
          &lt;/app-popover&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        Some tooltip to left on hover
          <app-popover [content]="'Some tooltip text'" [title]="'Some tooltip title'" [placement]="'left'">
            <app-svg-icons element [name]="'info'"></app-svg-icons>
          </app-popover>
      </td>
      <td colspan="2">
        <code>&lt;app-popover [content]="'Some tooltip text'" [title]="'Some tooltip title'" [placement]="'left'"&gt;<br>
          &lt;app-svg-icons element [name]="'info'"&gt;&lt;/app-svg-icons&gt;<br>
          &lt;/app-popover&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        Some tooltip to top on hover
        <app-popover [content]="'Some tooltip text'" [title]="'Some tooltip title'" [placement]="'top'">
          <app-svg-icons element [name]="'info'"></app-svg-icons>
        </app-popover>
      </td>
      <td colspan="2">
        <code>&lt;app-popover [content]="'Some tooltip text'" [title]="'Some tooltip title'" [placement]="'top'"&gt;<br>
          &lt;app-svg-icons element [name]="'info'"&gt;&lt;/app-svg-icons&gt;<br>
          &lt;/app-popover&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        Some tooltip to bottom on hover
        <app-popover [content]="'Some tooltip text'" [title]="'Some tooltip title'" [placement]="'bottom'">
          <app-svg-icons element [name]="'info'"></app-svg-icons>
        </app-popover>
      </td>
      <td colspan="2">
        <code>&lt;app-popover [content]="'Some tooltip text'" [title]="'Some tooltip title'" [placement]="'bottom'"&gt;<br>
          &lt;app-svg-icons element [name]="'info'"&gt;&lt;/app-svg-icons&gt;<br>
          &lt;/app-popover&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        Some tooltip to bottom no title on hover
        <app-popover [content]="'Some tooltip text without title'" [placement]="'bottom'">
          <app-svg-icons element [name]="'info'"></app-svg-icons>
        </app-popover>
      </td>
      <td colspan="2">
        <code>&lt;app-popover [content]="'Some tooltip text'" [placement]="'bottom'"&gt;<br>
          &lt;app-svg-icons element [name]="'info'"&gt;&lt;/app-svg-icons&gt;<br>
          &lt;/app-popover&gt;</code>
      </td>
    </tr>
  </tbody>
</table>

<h2>Alert messages</h2>

<table class="table table-striped">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Values</th>
      <th>Comment</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>[type]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'helper'</b> - default</li>
          <li><b>'success'</b></li>
          <li><b>'warning'</b></li>
          <li><b>'error'</b></li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>[title]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td></td>
    </tr>
    <tr>
      <td><b>[hidable]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td colspan="4">Examples</td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert><p>Простой алерт без заголовка и закрывашки</p></app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert&gt;&lt;p&gt;Простой алерт без заголовка и закрывашки&lt;/p&gt;&lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [title]="'Заголовок'" [hidable]="true">
          <p>Простой алерт с кнопками</p>
          <div class="btns-block">
            <app-button [type]="'button'" [color]="'primary'">Button</app-button>
            <app-button [type]="'button'" [color]="'transparent'">Transparent button</app-button>
          </div>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [title]="'Заголовок'" [hidable]="true"&gt;<br>
          &lt;p&gt;Простой алерт с кнопками&lt;/p&gt;<br>
          &lt;div class="btns-block"&gt;<br>
          &lt;app-button [type]="'button'" [color]="'primary'"&gt;Button&lt;/app-button&gt;<br>
          &lt;app-button [type]="'button'" [color]="'transparent'"&gt;Transparent button&lt;/app-button&gt;<br>
          &lt;/div&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [type]="'success'" [hidable]="true" [title]="'Success message title'">
          <p>"How many will be brave enough to return when they feel it?" he <a href="#">whispered</a>, his gleaming red eyes fixed upon the stars. "And how many will be foolish enough to stay away?"</p>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [type]="'success'" [hidable]="true" [title]="'Success message title'"&gt;<br>
          &lt;p&gt;"How many will be brave enough to return when they feel it?" he &lt;a href="#"&gt;whispered&lt;/a&gt;, his gleaming red eyes fixed upon the stars. "And how many will be foolish enough to stay away?"&lt;/p&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [type]="'warning'" [hidable]="true" [title]="'Warning message title'">
          <p>Cornelius Fudge was standing beside <a href="#">Dumbledore's</a> desk, wearing his usual pinstriped cloak and holding his lime-green bowler hat.</p>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [type]="'warning'" [hidable]="true" [title]="'Warning message title'"&gt;<br>
          &lt;p&gt;Cornelius Fudge was standing beside &lt;a href="#"&gt;Dumbledore's&lt;/a&gt; desk, wearing his usual pinstriped cloak and holding his lime-green bowler hat.&lt;/p&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [type]="'error'" [hidable]="true" [title]="'Error message title'">
          <p>"One drop each," said <a href="#">George</a>, rubbing his hands together with glee. "We only need to be a few months older."</p>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [type]="'error'" [hidable]="true" [title]="'Error message title'"&gt;<br>
          &lt;p&gt;"One drop each," said &lt;a href="#"&gt;George&lt;/a&gt;, rubbing his hands together with glee. "We only need to be a few months older."&lt;/p&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [type]="'info'" [hidable]="true" [title]="'Info message title'">
          <p>"I can't come with you," said <a href="#">Hermione</a>, now blushing, "because I'm already going with someone."</p>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [type]="'info'" [hidable]="true" [title]="'Info message title'"&gt;<br>
          &lt;p&gt;"I can't come with you," said &lt;a href="#"&gt;Hermione&lt;/a&gt;, now blushing, "because I'm already going with someone."&lt;/p&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [type]="'success'" [hidable]="true">
          <p>Hermione looked severely over at him too. "I'd have thought you'd be doing something <a href="#">constructive</a>, Harry, even if you don't want to learn your antidotes!"</p>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [type]="'success'" [hidable]="true"&gt;<br>
          &lt;p&gt;Hermione looked severely over at him too. "I'd have thought you'd be doing something &lt;a href="#"&gt;constructive&lt;/a&gt;, Harry, even if you don't want to learn your antidotes!"&lt;/p&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [type]="'warning'" [hidable]="true">
          <p>"Yes," said Hermione in a <a href="#">heated</a> voice, "he sacked her, just because she hadn't stayed in her tent and let herself get trampled -"</p>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [type]="'warning'" [hidable]="true"&gt;<br>
          &lt;p&gt;"Yes," said Hermione in a &lt;a href="#"&gt;heated&lt;/a&gt; voice, "he sacked her, just because she hadn't stayed in her tent and let herself get trampled -"&lt;/p&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [type]="'error'" [hidable]="true">
          <p>Dumbledore's voice carried no hint of a threat; it sounded like a mere statement, but <a href="#">Fudge</a> bristled as though Dumbledore were advancing upon him with a wand.</p>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [type]="'error'" [hidable]="true"&gt;<br>
          &lt;p&gt;Dumbledore's voice carried no hint of a threat; it sounded like a mere statement, but &lt;a href="#"&gt;Fudge&lt;/a&gt; bristled as though Dumbledore were advancing upon him with a wand.&lt;/p&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-alert [type]="'info'" [hidable]="true">
          <p>Mr. Crouch came next. He <a href="#">shot</a> a number nine into the air.</p>
        </app-alert>
      </td>
      <td colspan="2">
        <code>&lt;app-alert [type]="'info'" [hidable]="true"&gt;<br>
          &lt;p&gt;Mr. Crouch came next. He &lt;a href="#"&gt;shot&lt;/a&gt; a number nine into the air.&lt;/p&gt;<br>
          &lt;/app-alert&gt;</code>
      </td>
    </tr>
  </tbody>
</table>

<h2>Form messages</h2>
<table class="table table-striped">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Values</th>
      <th>Comment</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>[type]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'helper'</b> - default</li>
          <li><b>'success'</b></li>
          <li><b>'warning'</b></li>
          <li><b>'error'</b></li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>[message]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td></td>
    </tr>
    <tr>
      <td colspan="4">Examples</td>
    </tr>
    <tr>
      <td colspan="2">
        <app-message [type]="'helper'" [message]="'Some helper text'"></app-message>
      </td>
      <td colspan="2">
        <code>&lt;app-message [type]="'helper'" [message]="'Some helper text'"&gt;&lt;/app-message&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-message [type]="'success'" [message]="'Some helper text'"></app-message>
      </td>
      <td colspan="2">
        <code>&lt;app-message [type]="'success'" [message]="'Some helper text'"&gt;&lt;/app-message&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-message [type]="'warning'" [message]="'Some helper text'"></app-message>
      </td>
      <td colspan="2">
        <code>&lt;app-message [type]="'warning'" [message]="'Some helper text'"&gt;&lt;/app-message&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <app-message [type]="'error'" [message]="'Some helper text'"></app-message>
      </td>
      <td colspan="2">
        <code>&lt;app-message [type]="'error'" [message]="'Some helper text'"&gt;&lt;/app-message&gt;</code>
      </td>
    </tr>
  </tbody>
</table>





