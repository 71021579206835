<ng-container>
  <nav class="menu">
    <ul class="menu__list">
      <li class="menu__item"
          *ngFor="let alias of aliasList"
          [ngClass]="{'active': alias === currentAlias }"
      >
        <a href="javascript:;" class="menu__link" (click)="clickHandler(alias)">{{alias}}</a>
      </li>
    </ul>
  </nav>
</ng-container>
