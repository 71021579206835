import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-elements',
  templateUrl: './form-elements.component.html',
  styleUrls: ['./form-elements.component.less']
})
export class FormElementsComponent implements OnInit {

  radioItems: any;
  checkboxItems: any;
  constructor() { }

  ngOnInit() {

    this.radioItems = [
      {
        name: 'option 1',
        checked: true
      },
      { name: 'option 2'},
      { name: 'option 3'}
    ];
    this.checkboxItems = [
      { name: 'option 1'},
      {
        name: 'option 2',
        checked: true
      },
      { name: 'option 3'}
    ];
  }

}
