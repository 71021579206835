import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.less']
})
export class TablesComponent implements OnInit {
  /*dtOptions: any = {};
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;*/

  constructor() {
    /*this.dtOptions = {
      language: {
        emptyTable: 'Нет записей',
        infoEmpty: 'Не найдено записей',
        search: '',
        zeroRecords: 'Не найдено записей',
        searchPlaceholder: 'Введите наименование акции'
      },
      dom: '<"dataTables_filter_wrapper_r"f>tp',
      ordering: false,
      pagingType: 'simple_numbers',
      searching: true,
      responsive: true
    };*/
  }

  ngOnInit() {
    // this.dtElement.dtInstance.then(() =>
    /*setTimeout(function () {
        document.querySelector('.dataTables_filter_wrapper_r input').classList.add('form-control');
      }, 500
    );*/
    // );
  }

}
