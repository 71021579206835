export enum UnitEnum {
    h = 'h',
    m = 'm',
    w = 'w',
    d = 'd'
}

export class TimeSelectorTerm {
    unit: UnitEnum = UnitEnum.d;
    duration = 5;

    constructor(data?: TimeSelectorTermInterface) {
        if (data) {
            this.unit = UnitEnum[data.unit] as UnitEnum || UnitEnum.d;
            this.duration = Number(data.duration) || 5;
        }
    }

    getData(): Date {
        const date = new Date();
        switch (this.unit) {
            case UnitEnum.h : return date.setHours(date.getHours() + this.duration) && date;
            case UnitEnum.d : return date.setDate(date.getDate() + this.duration) && date;
            case UnitEnum.w : return date.setDate(date.getDate() + (this.duration * 7)) && date;
            case UnitEnum.m : return date.setDate(date.getDate() + (this.duration * 30)) && date;
        }
    }
}
export interface TimeSelectorTermInterface {
    unit: UnitEnum | string;
    duration: number;
}

export enum UnitEnumAbbreviation {
    h = 'ч.',
    m = 'мес.',
    w = 'нед.',
    d = 'дн.',
}
