import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.less']
})
export class StepperComponent implements OnInit {

  @Input() aliasList: any;
  @Input() currentAlias: string;
  constructor() { }

  ngOnInit() {
  }

}
