import { PipelineInterface, Pipeline } from './pipelines.model';
import { CustomStatusInterface, CustomStatus } from './custom-status.model';
import { CustomStatusBindingInterface, CustomStatusBinding } from './pipeline-binding.model';
import { QueueReserveSettings, QueueReserveSettingsInterface } from './queue.model';
import { ReserveSettingsInterface, ReserveSettings } from './reserve-settings.model';
import { CrmIndividualSettings, CrmIndividualSettingsInterface } from './crm-individual-settings.model';
import { VisitEventStatus, VisitEventStatusInterface } from './visit-event-status.model';

export class CustomError {
  code: number;
  message: string;
  link?: string;

  constructor(data?: CustomErrorInterface) {
    if (data) {
      this.code = data.code;
      this.message = data.message;
      this.link = data.link;
    }
  }
}

export interface FunnelResponseInterface {
  success: boolean;
  data?: {
    hasDeals?: boolean;
    id?: number;
    crmPipelines?: PipelineInterface[];
    customStatuses?: CustomStatusInterface[];
    pipelineBindings?: CustomStatusBindingInterface[];
    queueReserveSettings?: QueueReserveSettingsInterface[];
    reserveSettings?: ReserveSettingsInterface[];
    usersIndividualSettings?: CrmIndividualSettingsInterface[];
    visitEventStatuses?: VisitEventStatusInterface[];

  };

  errors?: CustomErrorInterface[];
}

export interface CustomErrorInterface {
  code: number;
  message: string;
  link?: string;
}

export class FunnelResponse implements FunnelResponseInterface {
  success: boolean;
  data?: {
    hasDeals?: boolean;
    id?: number;
    crmPipelines?: Pipeline[];
    customStatusBindings?: CustomStatusBinding[];
    customStatuses?: CustomStatus[];
    queueReserveSettings?: QueueReserveSettings[];
    reserveSettings?: ReserveSettings[];
    crmIndividualSettings?: CrmIndividualSettings[];
    visitEventStatuses?: VisitEventStatus[];
  };
  errors?: CustomError[];

  constructor(response?: FunnelResponseInterface, err?: CustomError[] | Error) {
    if (response) {
      this.success = Boolean(response.success);
      this.errors = response.errors && response.errors.map(error => new CustomError(error));

      if (response.data) {
        this.data = {};
        this.data.id = response.data.id && Number(response.data.id);
        this.data.hasDeals = Boolean(response.data.hasDeals);
        this.data.crmPipelines = response.data.crmPipelines && response.data.crmPipelines
          .map(cpp => new Pipeline(cpp));
        this.data.customStatuses = response.data.customStatuses && response.data.customStatuses
          .map(cpp => new CustomStatus(cpp));
        this.data.customStatusBindings = response.data.pipelineBindings && response.data.pipelineBindings
          .map(csb => new CustomStatusBinding(csb));
        this.data.queueReserveSettings = response.data.queueReserveSettings && response.data.queueReserveSettings
          .map(qrs => new QueueReserveSettings(qrs));
        this.data.reserveSettings = response.data.reserveSettings && response.data.reserveSettings
          .map(rs => new ReserveSettings(rs));
        this.data.crmIndividualSettings = response.data.usersIndividualSettings && response.data.usersIndividualSettings
          .map(cis => new CrmIndividualSettings(cis));
        this.data.visitEventStatuses = response.data.visitEventStatuses && response.data.visitEventStatuses
          .map(ves => new VisitEventStatus(ves));
      }

    } else if (err) {
      this.success = false;
      if (err instanceof Array) {
        this.errors = err.map(e => new CustomError(e));
      } else {
        this.errors = [new CustomError({
          message: err.message,
          code: err['status']
        })];
      }

      this.data = {
        crmPipelines: [],
        customStatusBindings: [],
        customStatuses: [],
      };
    }
  }
}


