import { Injectable, HostListener } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class AngularStylesModalService {

  private _modalIdList: { [string: string]: ModalComponent } = {};

  constructor() { }

  modalQueue: ModalComponent[] = [];
  activeModal: ModalComponent;

  @HostListener('click') backdropHandler() {
    this.activeModal.hide();
  }

  public addModal(modal: ModalComponent) {
    this._modalIdList[modal.id] = modal;
  }

  public modalShow(id: string, data?: any): void {
    const modal = this._modalIdList[id];
    if (modal && this.modalQueue.length > 0) {
      this.modalQueue.forEach(m => m.hide(true));
    }
    if (modal) {
      this.modalQueue.push(modal);
      this.activeModal = modal;
      modal.show(true);
      if (data) {
        modal.modalDataSubject.next(data);
      }
    }
  }

  public modalHide(id: string, data?: any): void {
    const modal = this._modalIdList[id];

    if (modal) {
      this.modalQueue = this.modalQueue.filter(m => m.id !== id);
      modal.hide(true);
    }

    if (modal && this.modalQueue.length > 0) {
      this.modalQueue[this.modalQueue.length - 1].show(true);
    }

    if (data) {
      modal.modalDataSubject.next(data);
    }
  }

  public removeModal(modal: ModalComponent) {
    this._modalIdList[modal.id] = null;
  }
}
