<div class="hidden">
  <app-svg-sprite></app-svg-sprite>
</div>

<!-- <app-headernew></app-headernew>

<div class="container page-container">
  <app-main-menu></app-main-menu>
  <div class="page-content-wrapper">
    <div class="page-content"> -->
<div class="not-ie">
      <router-outlet></router-outlet>
</div>    <!-- </div>
  </div>
  <app-footer></app-footer>
</div> -->

<style>
  .ie {
    display: none;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .ie {
      display: block;
    }

    .not-ie {
      display: none;
    }
  }

</style>

<div class="ie">
  <h1>Работоспособность раздела не поддерживается для браузера Internet Explorer</h1>
  <h2>Для корректной работы откройте Profitbase в браузерах
    <a href="https://www.google.com/intl/ru/chrome/" target="_blank">Google Chrome</a>,
    <a href="https://www.mozilla.org/ru/firefox/download/thanks/" target="_blank">Mozilla FireFox</a>,
    <a href="https://www.opera.com/ru" target="_blank">Opera</a> или
    <a href="https://browser.yandex.ru/" target="_blank">Яндекс.Браузер</a>
  </h2>
</div>
