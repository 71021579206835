<app-menu [aliasList]="['typography', 'tables', 'navigation', 'buttons', 'inputs', 'selects', 'colorpickers', 'textareas', 'alerts', 'icons', 'modals', 'formGroups', 'statuses', 'queues', 'stages']"
          [currentAlias]="currentAlias"
          (aliasEmitter)="menuClickHandler($event)"></app-menu>


<router-outlet></router-outlet>

<app-typography *ngIf="(view | async) === 'typography'"></app-typography>
<app-tables *ngIf="(view | async) === 'tables'"></app-tables>
<app-navigation *ngIf="(view | async) === 'navigation'"></app-navigation>
<app-form-elements *ngIf="(view | async) === 'inputs'"></app-form-elements>
<app-selects *ngIf="(view | async) === 'selects'"></app-selects>
<app-colorpickers *ngIf="(view | async) === 'colorpickers'"></app-colorpickers>
<app-textareas *ngIf="(view | async) === 'textareas'"></app-textareas>
<app-buttons *ngIf="(view | async) === 'buttons'"></app-buttons>
<app-alerts *ngIf="(view | async) === 'alerts'"></app-alerts>
<app-icons *ngIf="(view | async) === 'icons'"></app-icons>
<app-modals *ngIf="(view | async) === 'modals'"></app-modals>
<app-form-groups *ngIf="(view | async) === 'formGroups'"></app-form-groups>
<app-statuses *ngIf="(view | async) === 'statuses'"></app-statuses>
<app-queues *ngIf="(view | async) === 'queues'"></app-queues>
<app-stages *ngIf="(view | async) === 'stages'"></app-stages>

