<h1 class="page-title">Queues</h1>

<app-modal #modalQueue [id]="'modalQueue'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalQueue')">Настройка очереди бронирования</a>
  <ng-container title>
    Настройка очереди бронирования
  </ng-container>
  <ng-container content>
    <app-form [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group>
        <ng-container label>Формирование очереди происходит на этапе</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>При наступлении очереди переводить сделку на этап</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
            <ng-container label>Уведомление менеджеру при наступлении очереди</ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-input [type]="'text'"
                     [placeholder]="'Очередь на помещение подошла, свяжитесь с клиентом.'"
                     [label]="false"
          ></app-input>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id2'">
            <ng-container label>
              Удалить очередь на этапе:
              <app-popover [content]="'При попадании на выбранный этап воронки очередь на помещение удаляется. Вы можете настроить автоматическое уведомление менеджерам, находящимся в очереди.'" [placement]="'right'">
                <app-svg-icons element [name]="'help-small'"></app-svg-icons>
              </app-popover>
            </ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalQueue')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalQueue')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
<app-modal #modalDeleteQueue [id]="'modalDeleteQueue'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalDeleteQueue')">Настройка очереди бронирования - удалять на этапе</a>
  <ng-container title>
    Настройка очереди бронирования
  </ng-container>
  <ng-container content>
    <app-form [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group>
        <ng-container label>Формирование очереди происходит на этапе</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>При наступлении очереди переводить сделку на этап</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [checked]="true" [layout]="'single'" [id]="'id'">
            <ng-container label>Уведомление менеджеру при наступлении очереди</ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-input [type]="'text'"
                     [placeholder]="'Очередь на помещение подошла, свяжитесь с клиентом.'"
                     [label]="false"
          ></app-input>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [checked]="true" [layout]="'single'" [id]="'id2'">
            <ng-container label>
              Удалить очередь на этапе:
              <app-popover [content]="'При попадании на выбранный этап воронки очередь на помещение удаляется. Вы можете настроить автоматическое уведомление менеджерам, находящимся в очереди.'" [placement]="'right'">
                <app-svg-icons element [name]="'help-small'"></app-svg-icons>
              </app-popover>
            </ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [checked]="true" [layout]="'single'" [id]="'id3'">
            <ng-container label>Уведомление менеджеру об удалении очереди</ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-textarea [rows]="2"
                        [label]="false"
                        [placeholder]="'Помещение, которым ранее интересовался клиент, продано. Свяжитесь с клиентом.'"></app-textarea>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalDeleteQueue')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalDeleteQueue')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
