import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export class State {
    private pageSource = new BehaviorSubject<PageEnum>(null);
    public readonly page: Observable<PageEnum> = this.pageSource.pipe(distinctUntilChanged());


    private viewSource = new BehaviorSubject<ViewEnum>(null);
    public readonly view: Observable<ViewEnum> = this.viewSource.pipe(distinctUntilChanged());

    private _newState: StateInterface;

    private replaceIfOwn(key: string) {
        const value = this._newState[key] && this._newState[key];
        switch (value && key) {
            case 'page': this.pageSource.next(value);
                break;
            case 'view': this.viewSource.next(value);
                break;
        }
    }

    public setState(state: StateInterface = {}) {
        if (state) {
            this._newState = state;
            this.replaceIfOwn('page');
            this.replaceIfOwn('view');
        }
    }

    constructor(state: StateInterface = {}) {
        this.setState(state);
    }
}

export class StateEvent {
    state: State;
    actionBy: string;
    replaceHistory?: boolean;
    withoutChangeRoute?: boolean;
}

export class StateInterface {
    page?: PageEnum;
    view?: ViewEnum;
}

export enum PageEnum {
    FUNNEL = 'funnel',
    DOCUMENTATION = 'documentation'
}

export enum ViewEnum {
    typography = 'typography',
    tables = 'tables',
    navigation = 'navigation',
    buttons = 'buttons',
    inputs = 'inputs',
    alerts = 'alerts',
    icons = 'icons',
    selects = 'selects',
    colorpickers = 'colorpickers',
    textareas = 'textareas',
    modals = 'modals',
    formGroups = 'formGroups',
    statuses = 'statuses',
    queues = 'queues',
    stages = 'stages'
}
