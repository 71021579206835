<ng-container *ngIf="show" >
  <div class="loader show"
       [ngClass]="{'loader_fixed': fullscreen,
                   'loader_red': color === 'red' && !isBDay,
                   'loader_blue': color === 'blue'&& !isBDay,
                   'loader_black': color === 'black' && !isBDay,
                   'loader_secret': color === 'secret' || isBDay}">
    <div class="loader__container">
      <span class="loader__outer"></span>
      <span class="loader__inner">
        <app-svg-icons [name]="name"></app-svg-icons>
      </span>
    </div>
  </div>
</ng-container>
