<ng-container>
  <ul class="pagination__list">
    <li class="pagination__item">
      <a [attr.href]="pages[currentPage - 1]"
         class="pagination__link previous"
         [ngClass]="{'disabled': currentPage === 0}">
        <app-svg-icons [name]="'caret-left'"></app-svg-icons>
        Назад
      </a>
    </li>
    <li *ngFor="let page of pages, let i = index" class="pagination__item">
      <a [attr.href]="page" *ngIf="i !== currentPage - 1" class="pagination__link">{{i + 1}}</a>
      <span *ngIf="i === currentPage - 1" class="pagination__link active">{{i + 1}}</span>
    </li>
    <li class="pagination__item">
      <a [attr.href]="pages[currentPage + 1]"
         class="pagination__link next"
         [ngClass]="{'disabled': currentPage === pages.length - 1}">
        Далее
        <app-svg-icons [name]="'caret-right'"></app-svg-icons>
      </a>
    </li>
  </ul>
</ng-container>
