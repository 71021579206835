import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-icons',
  templateUrl: './svg-icons.component.html',
  styleUrls: ['./svg-icons.component.less']
})
export class SvgIconsComponent implements OnInit {

  @Input() name: string;
  @Input() customClass: string;
  @Input() inline: boolean = true;
  @Input() alt: string;
  @Input() height: number = 32;
  @Input() width: number = 32;
  constructor() { }

  ngOnInit() {
  }

}
