import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Component, Input, EventEmitter, OnInit, Output, Self, Optional, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { CustomDataAccessorBaseComponent } from '../baseComponents/customDataAccessorBaseComponent';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.less']
})
export class TextareaComponent extends CustomDataAccessorBaseComponent implements OnChanges, OnInit{
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() id: string;
  @Input() required: boolean = true;
  @Input() label: boolean = true;
  @Input() color: string;
  @Input() message: string;
  @Input() rows: number;
  @Input() resize: boolean = true;

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

   ngOnChanges(sc: SimpleChanges) {
    if (sc.value) {
      this.onChange(sc.value.currentValue);
    }
  }

  ngOnInit() {
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public ref: ChangeDetectorRef
  ) {
    super();
    if (ngControl) { ngControl.valueAccessor = this; }
  }

  public valueChangeHandler(value): void {
    // this.valueChange.emit(value);
    this.writeValue(value);
  }

}
