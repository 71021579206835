<h1 class="page-title">Radio/Checkbox/Input</h1>

<h2>Radiobuttons</h2>

<table class="table table-striped">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Values</th>
      <th>Comment</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>[layout]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'single'</b></li>
          <li><b>'list'</b></li>
          <li><b>'inline-list'</b></li>
          <li><b>'toggle'</b></li>
          <li><b>'toggle-small'</b></li>
        </ul>
      </td>
      <td>Required, no default value!</td>
    </tr>
    <tr>
      <td><b>[checked]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>Undefined</b> - default</li>
        </ul>
      </td>
      <td>Only for 'single' layout</td>
    </tr>
    <tr>
      <td><b>[items]</b></td>
      <td>array of objects</td>
      <td>[{{ '{' }} name: 'option 1', checked: true {{ '}' }}, {{ '{' }} name: 'option 2'{{ '}' }}, {{ '{' }} name: 'option 3'{{ '}' }}]</td>
      <td>Not for 'single' layout</td>
    </tr>
    <tr>
      <td><b>[name]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td>Required</td>
    </tr>
    <tr>
      <td><b>[id]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td>Required</td>
    </tr>
    <tr>
      <td><b>[disabled]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>[required]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td colspan="4">Examples</td>
    </tr>
    <tr>
      <td>
        <app-radio-button [layout]="'single'" [id]="'idRadio'" [name]="'someradio'">Some single radiobutton</app-radio-button>
      </td>
      <td colspan="3">
        <code>&lt;app-radio-button [layout]="'single'" [id]="'idRadio'" [name]="'someradio'"&gt;Some single radiobutton&lt;/app-radio-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-radio-button [layout]="'single'" [id]="'idRadioDis'" [disabled]="true">Some disabled radiobutton</app-radio-button>
      </td>
      <td colspan="3">
        <code>&lt;app-radio-button [layout]="'single'" [id]="'idRadioDis'" [disabled]="true"&gt;Some disabled radiobutton&lt;/app-radio-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-radio-button [layout]="'list'" [items]="radioItems" [id]="'idRadio_'" [name]="'radioList'"></app-radio-button>
      </td>
      <td colspan="3">
        <code>&lt;app-radio-button [layout]="'list'" [items]="radioItems" [id]="'idRadio_'" [name]="'radioList'"&gt;&lt;/app-radio-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-radio-button [layout]="'inline-list'" [items]="radioItems" [id]="'idRadio__'" [name]="'radioListInline'"></app-radio-button>
      </td>
      <td colspan="3">
        <code>&lt;app-radio-button [layout]="'inline-list'" [items]="radioItems" [id]="'idRadio__'" [name]="'radioListInline'"&gt;&lt;/app-radio-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-radio-button [layout]="'toggle'" [items]="radioItems" [id]="'idRadio_tog'" [name]="'radioToggle'"></app-radio-button>
      </td>
      <td colspan="3">
        <code>&lt;app-radio-button [layout]="'toggle'" [items]="radioItems" [id]="'idRadio_tog'" [name]="'radioToggle'"&gt;&lt;/app-radio-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-radio-button [layout]="'toggle-small'" [items]="radioItems" [id]="'idRadio_toggle'" [name]="'radioToggleSmall'"></app-radio-button>
      </td>
      <td colspan="3">
        <code>&lt;app-radio-button [layout]="'toggle-small'" [items]="radioItems" [id]="'idRadio_toggle'" [name]="'radioToggleSmall'"&gt;&lt;/app-radio-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-radio-button [layout]="'toggle'" [items]="radioItems" [id]="'idRadio_togDis'" [disabled]="true" [name]="'radioToggleDis'"></app-radio-button>
      </td>
      <td colspan="3">
        <code>&lt;app-radio-button [layout]="'toggle'" [items]="radioItems" [id]="'idRadio_togDis'" [disabled]="true" [name]="'radioToggleDis'"&gt;&lt;/app-radio-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-radio-button [layout]="'toggle-small'" [items]="radioItems" [id]="'idRadio_toggleDis'" [disabled]="true" [name]="'radioToggleSmallDis'"></app-radio-button>
      </td>
      <td colspan="3">
        <code>&lt;app-radio-button [layout]="'toggle-small'" [items]="radioItems" [id]="'idRadio_toggleDis'" [disabled]="true" [name]="'radioToggleSmallDis'"&gt;&lt;/app-radio-button&gt;</code>
      </td>
    </tr>
  </tbody>
</table>

<h2>Checkboxes</h2>

<table class="table table-striped">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Values</th>
      <th>Comment</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>[type]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'checkbox'</b></li>
          <li><b>'switch'</b></li>
        </ul>
      </td>
      <td>Required, no default value!</td>
    </tr>
    <tr>
      <td><b>[layout]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'single'</b></li>
          <li><b>'list'</b></li>
          <li><b>'inline-list'</b></li>
        </ul>
      </td>
      <td>Required, no default value!</td>
    </tr>
    <tr>
      <td><b>[color]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'success'</b></li>
          <li><b>'warning'</b></li>
          <li><b>'error'</b></li>
          <li><b>Undefined</b> - default</li>
        </ul>
      </td>
      <td>Only for [type]='switch', undefined is for regular color</td>
    </tr>
    <tr>
      <td><b>[checked]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>Undefined</b> - default</li>
        </ul>
      </td>
      <td>Only for 'single' layout</td>
    </tr>
    <tr>
      <td><b>[items]</b></td>
      <td>array of objects</td>
      <td>[{{ '{' }} name: 'option 1', checked: true {{ '}' }}, {{ '{' }} name: 'option 2'{{ '}' }}, {{ '{' }} name: 'option 3'{{ '}' }}]</td>
      <td>Not for 'single' layout</td>
    </tr>
    <tr>
      <td><b>[name]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td></td>
    </tr>
    <tr>
      <td><b>[id]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td>Required</td>
    </tr>
    <tr>
      <td><b>[disabled]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>[required]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>[message]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td>Only for single layout</td>
    </tr>
    <tr>
      <td><b>[messageType]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'error'</b></li>
          <li><b>'warning'</b></li>
          <li><b>'success'</b></li>
          <li><b>Undefined</b> - default</li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>#label</b></td>
      <td>directive</td>
      <td>
        &lt;ng-container label>Some label&lt;/ng-container>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>#message</b></td>
      <td>directive</td>
      <td>
        &lt;ng-container message>Some message&lt;/ng-container>
      </td>
      <td></td>
    </tr>
    <tr>
      <td colspan="4">Examples</td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
          <ng-container label>Some single checkbox</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'"&gt;<br>
          &lt;ng-container label>Some single checkbox&lt;/ng-container><br>
          &lt;/app-checkbox&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'" [message]="true" [messageType]="'error'">
          <ng-container label>Some single checkbox</ng-container>
          <ng-container message>Some error message</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'" [message]="true" [messageType]="'error'"&gt;<br>
          &lt;ng-container label>Some single checkbox&lt;/ng-container><br>
          &lt;ng-container message>Some error message&lt;/ng-container><br>
          &lt;/app-checkbox&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'idDis'" [disabled]="true">
          <ng-container label>Some disabled checkbox</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'idDis'" [disabled]="true"&gt;<br>
          &lt;ng-container label>Some single checkbox&lt;/ng-container><br>
          &lt;/app-checkbox&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'checkbox'" [layout]="'list'" [items]="checkboxItems" [id]="'checkboxId'"></app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'checkbox'" [layout]="'list'" [items]="checkboxItems" [id]="'checkboxId'"&gt;&lt;/app-checkbox&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'checkbox'" [layout]="'inline-list'" [items]="checkboxItems" [id]="'checkboxId_'"></app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'checkbox'" [layout]="'inline-list'" [items]="checkboxItems" [id]="'checkboxId_'">&lt;/app-checkbox&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id1'">
          <ng-container label>Some single switch</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id1'"&gt;<br>
          &lt;ng-container label>Some single checkbox&lt;/ng-container><br>
          &lt;/app-checkbox&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id12'" [message]="true" [messageType]="'error'">
          <ng-container label>Some single switch</ng-container>
          <ng-container message>Some error message</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id12'" [message]="true" [messageType]="'error'"&gt;<br>
          &lt;ng-container label>Some single checkbox&lt;/ng-container><br>
          &lt;ng-container message>Some error message&lt;/ng-container><br>
          &lt;/app-checkbox&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'switch'" [layout]="'single'" [id]="'idDis1'" [disabled]="true">
          <ng-container label>Some disabled switch</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'switch'" [layout]="'single'" [id]="'idDis1'" [disabled]="true"><br>
          &lt;ng-container label>Some disabled switch&lt;/ng-container><br>
          &lt;/app-checkbox></code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id2'" [checked]="true" [color]="'success'">
          <ng-container label>Some success switch</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id2'" [checked]="true" [color]="'success'"><br>
          &lt;ng-container label>Some success switch&lt;/ng-container><br>
          &lt;/app-checkbox></code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id3'" [checked]="true" [color]="'warning'">
          <ng-container label>Some warning switch</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id3'" [checked]="true" [color]="'warning'"><br>
          &lt;ng-container label>Some warning switch&lt;/ng-container><br>
          &lt;/app-checkbox></code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id4'" [checked]="true" [color]="'error'">
          <ng-container label>Some error switch</ng-container>
        </app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'switch'" [layout]="'single'" [id]="'id4'" [checked]="true" [color]="'error'"><br>
          &lt;ng-container label>Some error switch&lt;/ng-container><br>
          &lt;/app-checkbox></code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'switch'" [layout]="'list'" [items]="checkboxItems" [id]="'switchId'"></app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'switch'" [layout]="'list'" [items]="checkboxItems" [id]="'switchId'">&lt;/app-checkbox&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-checkbox [type]="'switch'" [layout]="'inline-list'" [items]="checkboxItems" [id]="'switchId_'"></app-checkbox>
      </td>
      <td colspan="3">
        <code>&lt;app-checkbox [type]="'switch'" [layout]="'inline-list'" [items]="checkboxItems" [id]="'switchId_'">&lt;/app-checkbox&gt;</code>
      </td>
    </tr>
  </tbody>
</table>

<h2>Инпуты</h2>

<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[type]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'text'</b></li>
        <li><b>'password'</b></li>
        <li><b>'email'</b></li>
        <li><b>'tel'</b></li>
        <li><b>'search'</b></li>
        <li><b>'number'</b></li>
      </ul>
    </td>
    <td>Required, no default value!</td>
  </tr>
  <tr>
    <td><b>[color]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'success'</b></li>
        <li><b>'warning'</b></li>
        <li><b>'error'</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td>undefined is for regular color</td>
  </tr>
  <tr>
    <td><b>[message]</b></td>
    <td>string</td>
    <td>any string</td>
    <td>
      The [color] of message is the same as [color] of input.<br>
      If other [color] is needed - use separate app-message instead of [message]
    </td>
  </tr>
  <tr>
    <td><b>[placeholder]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[value]</b></td>
    <td>any</td>
    <td>any</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[name]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[id]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[idMessage]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[iconLeft]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[iconRight]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[disabled]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[required]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[label]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b> - default</li>
        <li><b>false</b></li>
      </ul>
    </td>
    <td>Use to turn off #label</td>
  </tr>
  <tr>
    <td><b>#label</b></td>
    <td>directive</td>
    <td>
      <pre><code>&lt;ng-container label&gt;Some label&lt;/ng-container&gt;</code></pre>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>#icon</b></td>
    <td>directive</td>
    <td>
      <pre><code>&lt;ng-container icon&gt;&lt;app-svg-icons [name]="'eye'"&gt;&lt;/app-svg-icons&gt;&lt;/ng-container&gt;</code></pre>
    </td>
    <td></td>
  </tr>
  <tr>
    <td  colspan="4">
      <b>NB! Use [id] and [idMessage] for a11y</b>
    </td>
  </tr>
  <tr>
    <td colspan="4">Examples</td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Текстовое поле'" [id]="'textInput'">
          <ng-container label>Текстовое поле</ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Текстовое поле'" [id]="'textInput'"&gt;<br>
        &lt;ng-container label&gt;Текстовое поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Текстовое поле'" [message]="'Some helper text'" [id]="'textInput1'" [idMessage]="'textInput1Message'">
          <ng-container label>Текстовое поле + helper</ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Текстовое поле'" [message]="'Some helper text'" [id]="'textInput1'" [idMessage]="'textInput1Message'"&gt;<br>
        &lt;ng-container label&gt;Текстовое поле + helper&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'password'" [placeholder]="'Password поле'" [iconRight]="true">
          <ng-container label>Password поле (icon eye)</ng-container>
          <ng-container icon><app-svg-icons [name]="'eye'"></app-svg-icons></ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'password'" [placeholder]="'Password поле'" [iconRight]="true"&gt;<br>
        &lt;ng-container label&gt;Password поле (icon eye)&lt;/ng-container&gt;<br>
        &lt;ng-container icon&gt;&lt;app-svg-icons [name]="'eye'"&gt;&lt;/app-svg-icons&gt;&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'email'" [placeholder]="'Email поле'">
          <ng-container label>Email поле</ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'email'" [placeholder]="'Email поле'"&gt;<br>
        &lt;ng-container label&gt;Email поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'tel'" [placeholder]="'Tel поле'">
          <ng-container label>Tel поле</ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'tel'" [placeholder]="'Tel поле'"&gt;<br>
        &lt;ng-container label&gt;Tel поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-input [type]="'search'" [placeholder]="'Search поле'">
        <ng-container label>Search поле</ng-container>
      </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'search'" [placeholder]="'Search поле'"&gt;<br>
        &lt;ng-container label&gt;Search поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <label class="control-label">Number поле</label>
      <app-input [type]="'number'" [placeholder]="'Number поле'"></app-input>
    </td>
    <td colspan="2">
      <code>&lt;label class="control-label"&gt;Number поле&lt;/label&gt;<br>
      &lt;app-input [type]="'number'" [placeholder]="'Number поле'"&gt;&lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <label class="control-label">Number disabled поле</label>
      <app-input [type]="'number'" [placeholder]="'Number disabled поле'" [disabled]="true" value="12"></app-input>
    </td>
    <td colspan="2">
      <code>&lt;label class="control-label"&gt;Number disabled поле&lt;/label&gt;<br>
      &lt;app-input [type]="'number'" [placeholder]="'Number disabled поле'" [disabled]="true"&gt;&lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Required поле'" [required]="true">
          <ng-container label>Required поле</ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Required поле'" [required]="true"&gt;<br>
        &lt;ng-container label&gt;Required поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Disabled поле'" [disabled]="true" value="Disabled поле">
          <ng-container label>Disabled поле</ng-container>
        </app-input><br>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Disabled поле'" [disabled]="true"&gt;<br>
        &lt;ng-container label&gt;Disabled поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Поле с иконкой слева'" [iconLeft]="true">
          <ng-container label>Поле с иконкой слева</ng-container>
          <ng-container icon><app-svg-icons [name]="'cms'"></app-svg-icons></ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Поле с иконкой слева'" [iconLeft]="true"&gt;<br>
        &lt;ng-container label&gt;Поле с иконкой слева&lt;/ng-container&gt;<br>
        &lt;ng-container icon&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'disabled Поле с иконкой слева'" [disabled]="true" [iconLeft]="true">
          <ng-container label>disabled Поле с иконкой слева</ng-container>
          <ng-container icon><app-svg-icons [name]="'cms'"></app-svg-icons></ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'disabled Поле с иконкой слева'" [disabled]="true" [iconLeft]="true"&gt;<br>
        &lt;ng-container label&gt;disabled Поле с иконкой слева&lt;/ng-container&gt;<br>
        &lt;ng-container icon&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Поле с иконкой справа'" [iconRight]="true">
          <ng-container label>Поле с иконкой справа</ng-container>
          <ng-container icon><app-svg-icons [name]="'cms'"></app-svg-icons></ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Поле с иконкой справа'" [iconRight]="true"&gt;<br>
        &lt;ng-container label&gt;Поле с иконкой справа&lt;/ng-container&gt;<br>
        &lt;ng-container icon&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Success поле'" [color]="'success'" [message]="'Some helper text'">
          <ng-container label>Success поле</ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Success поле'" [color]="'success'" [message]="'Some helper text'"&gt;<br>
        &lt;ng-container label&gt;Success поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Warning поле'" [color]="'warning'" [message]="'Some helper text'">
          <ng-container label>Warning поле</ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Warning поле'" [color]="'warning'" [message]="'Some helper text'"&gt;<br>
        &lt;ng-container label&gt;Warning поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <app-input [type]="'text'" [placeholder]="'Error поле'" [color]="'error'" [message]="'Some helper text'">
          <ng-container label>Error поле</ng-container>
        </app-input>
    </td>
    <td colspan="2">
      <code>&lt;app-input [type]="'text'" [placeholder]="'Error поле'" [color]="'error'" [message]="'Some helper text'"&gt;<br>
        &lt;ng-container label&gt;Error поле&lt;/ng-container&gt;<br>
        &lt;/app-input&gt;</code>
    </td>
  </tr>
  </tbody>
</table>
















