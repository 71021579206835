
<ng-container *ngIf="type === 'button' || type === 'submit' || type === 'reset'">
  <button [attr.class]="setClass()"
          [id]="id"
          [attr.type]="type"
          [attr.aria-label]="ariaLabel"
          [attr.disabled]="disabled ? '' : null"
          role="button"><ng-container *ngTemplateOutlet="btnContent"></ng-container></button>
</ng-container>

<ng-container *ngIf="type === 'link'">
  <a [attr.download]="download || null"
     [attr.href]="href"
     [attr.class]="setClass()"
     [id]="id"
     [attr.aria-label]="ariaLabel"
     role="link"><ng-container *ngTemplateOutlet="btnContent"></ng-container></a>
</ng-container>

<ng-template #btnContent>
  <ng-content></ng-content>
</ng-template>



