<ng-container *ngIf="inline">
  <svg class="svg-icon" [ngClass]="customClass"><use [attr.xlink:href]="'#' + name"></use></svg>
</ng-container>

<ng-container *ngIf="!inline">
  <img [ngClass]="customClass"
       [attr.src]="'../../style-assets/svg/' + name + '.svg'"
       [attr.alt]="alt"
       [attr.height]="height"
       [attr.width]="width">
</ng-container>
