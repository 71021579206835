import { CustomDataAccessorBaseComponent } from '../baseComponents/customDataAccessorBaseComponent';
import { NgControl } from '@angular/forms';
import {
  Component, Input, Output, EventEmitter, OnChanges, OnInit,
  ChangeDetectionStrategy, SimpleChanges, ViewChild, ElementRef, forwardRef, Self, Optional, ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.less'],
})
export class ColorpickerComponent extends CustomDataAccessorBaseComponent implements OnChanges, OnInit {

  @Input() colors = ['#A54500', '#FFB400', '#EB3235', '#CD58C1', '#8342D6', '#2E89EC',
    '#2ECAEC', '#63CBA5', '#98E000', '#E2DA1D', '#A6A6A6', '#263044'];
  @Input() name: string;
  @Input() id: string;
  @Input() value: string;
  @Input() required = true;
  @Input() error: boolean;

  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  @ViewChild('colorpicker', { static: true }) colorpicker: ElementRef;


  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public ref: ChangeDetectorRef
  ) {
    super();
    if (ngControl) { ngControl.valueAccessor = this; }
  }


  ngOnChanges(sc: SimpleChanges) {
    if (sc.value) {
      this.onChange(sc.value.currentValue);
    } else {
      this.reset();
    }
  }

  ngOnInit() {
    this.ngControl.valueChanges
      .subscribe(s => this.reset());
  }

  reset() {
    Array.from(this.colorpicker.nativeElement.querySelectorAll('input'))
      .forEach((el: any) => el.checked = el.value === this.value);
    this.ref.markForCheck();
  }

  clickHandler(value: string): void {
    // this.valueChange.emit(value);
    this.writeValue(value);
  }
}
