import {
  Component, Input, ChangeDetectionStrategy, OnInit, EventEmitter,
  Output, ViewChild, forwardRef, ChangeDetectorRef, Self, Optional, OnChanges, SimpleChanges
} from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { CustomDataAccessorBaseComponent } from '../baseComponents/customDataAccessorBaseComponent';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.less'],
})
// TODO
export class InputComponent extends CustomDataAccessorBaseComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() disabled: boolean;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() value: any;
  @Input() name: string;
  @Input() id: string;
  @Input() label = true;
  @Input() required = true;
  @Input() iconLeft: boolean;
  @Input() iconRight: boolean;
  @Input() color: string;
  @Input() message: string;
  @Input() idMessage: string;

  @Input() max = 999999;
  @Input() min = 1;


  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('input', { static: false }) input: NgModel;

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public ref: ChangeDetectorRef
  ) {
    super();
    if (ngControl) { ngControl.valueAccessor = this; }
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc.value) {
      this.onChange(sc.value.currentValue);
    }
  }

  changeValue(value) {
    // console.log(this.ngControl);
    this.writeValue(value);
  }

  ngOnInit() {
    this.valueChange.emit(this.value);
    // console.log(this.showError)
  }

  valueIncrease(increment: number) {
    const { value, min, max } = this;
    this.value = increment > 0 ? (value < max && value + 1 || max) : (value > min && value - 1 || min);
    this.writeValue(this.value);
  }
}
