export class AuthResponse {
  remainingTime: number;
  accessToken: string;
  lang: string;

  constructor(data: AuthResponseInterface) {
    this.accessToken = data.access_token;
    this.lang = data.access_token;
    this.remainingTime = Number(data.remaining_time);
  }
}

export interface AuthResponseInterface {
  remaining_time: number;
  access_token: string;
  lang: string;
}

export class AuthRequest {
  credentials: Credentials;
  type: AuthTypeEnum;

  constructor(data: AuthRequestInterface) {
    this.credentials = new Credentials(data.credentials);
    this.type = data.type;
  }
}

export interface AuthRequestInterface {
  credentials: CredentialsInterface;
  type: AuthTypeEnum;
}

export class Credentials {
  pb_api_key: string;

  constructor(data: CredentialsInterface) {
    if (data) {
      this.pb_api_key = data.pbApiKey;
    }
  }
}

export interface CredentialsInterface {
  pbApiKey: string;
}

export enum AuthTypeEnum {
  BITRIX = 'profit-user-bitrix',
  AMO = 'profit-user-amo',
  CREATIO = 'profit-user-creatio',
  SBER = 'profit-user-sber',
  PROFIT = 'profit-user'
}


