import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthResponse, AuthRequest, AuthResponseInterface } from '../models/auth.models';
import { switchMap, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private _authResponse: AuthResponse;
  private _authRequest: AuthRequest;
  private _sharedRequest: Observable<AuthResponse>;

  private get authRequest(): AuthRequest {
    return this._authRequest;
  }

  public lang = 'ru';

  public get authResponse(): Observable<AuthResponse> {
    return this._authResponse ? of(this._authResponse) : this.getAuth().pipe(share());
  }

  constructor(private http: HttpClient) { }

  public getAuth(): Observable<AuthResponse> {

    if (!this._sharedRequest) {
      this._sharedRequest = this.http.post('authentication', this.authRequest)
        .pipe(
          switchMap((res: AuthResponseInterface) => {
            this._sharedRequest = undefined;
            this._authResponse = new AuthResponse(res);
            return this.authResponse;
          }),
          share());
    }

    return this._sharedRequest;
  }

  public setAuthRequest(authRequest: AuthRequest) {
    this._authRequest = authRequest;
  }

  public resetAuthToken(): void {
    this._sharedRequest = null;
    this._authResponse = null;
  }
}
