import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less']
})
export class ButtonComponent implements OnInit {
  @Input() ariaLabel: string;
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() href: string;
  @Input() type: string;
  @Input() size: string;
  @Input() outline: boolean;
  @Input() gradient: boolean;
  @Input() color: string;
  @Input() onlyIcon: boolean;
  @Input() customClass: string;
  @Input() download: boolean;

  constructor() { }

  ngOnInit() {
  }

  setClass() {
    let classList = 'btn';

    if (this.customClass) {
      classList += ' ' + this.customClass;
    }

    if (this.color === 'primary' || !this.color) {
      classList += ' btn_primary';
    }

    if (this.color === 'brand') {
      classList += ' btn_brand';
    }

    if (this.color === 'white') {
      classList += ' btn_white';
    }

    if (this.color === 'transparent') {
      classList += ' btn_transparent';
    }

    if (this.size === 'small') {
      classList += ' btn_sm';
    }

    if (this.outline) {
      classList += ' btn_outline';
    }

    if (this.gradient) {
      classList += ' btn_gradient';
    }

    if (this.onlyIcon) {
      classList += ' btn_only-icon';
    }

    if (this.disabled) {
      classList += ' btn_disabled';
    }

    return classList;
  }
}
