export interface VisitEventStatusInterface {
  crmStatusId: string | number;
  crmPipelineId: string | number;
  active: boolean;
  id: number;
}

export class VisitEventStatus implements VisitEventStatusInterface {
  crmStatusId: string | number;
  crmPipelineId: string | number;
  active: boolean;
  id: number;

  constructor(data?: VisitEventStatusInterface) {
    if (data) {
      this.crmStatusId = data.crmStatusId;
      this.crmPipelineId = data.crmPipelineId;
      this.active = !!data.active;
      this.id = Number(data.id);
    }
  }
}