<ng-container>
  <ng-container *ngTemplateOutlet="modalElement"></ng-container>

  <div class="modal fade" bsModal #staticModal="bs-modal" [config]="config"
  (onHidden)="hide(false,  $event)"
       tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog"
         [ngClass]="{'modal-sm': size === 'small',
                     'modal-lg': size === 'large',
                     'modal_iconed': type,
                     'modal_info': type === 'info',
                     'modal_success': type === 'success',
                     'modal_warning': type === 'warning',
                     'modal_error': type === 'error'}">
      <div class="modal-content">
        <div class="modal-header" *ngIf="title">
          <h4 class="modal-title pull-left">
            <app-svg-icons *ngIf="type === 'info'" [name]="'alarm'" [customClass]="'svg-icon_colored'"></app-svg-icons>
            <app-svg-icons *ngIf="type === 'success'" [name]="'checked'" [customClass]="'svg-icon_colored'"></app-svg-icons>
            <app-svg-icons *ngIf="type === 'warning'" [name]="'info'" [customClass]="'svg-icon_colored'"></app-svg-icons>
            <app-svg-icons *ngIf="type === 'error'" [name]="'warning'" [customClass]="'svg-icon_colored'"></app-svg-icons>
            <ng-content select="[title]"></ng-content>
          </h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
            <app-svg-icons [name]="'delete'"></app-svg-icons>
          </button>
        </div>
        <div class="modal-body"
             [ngClass]="{'modal-body_no-title': !title}">
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeClickHandler()" *ngIf="!title">
            <app-svg-icons [name]="'delete'"></app-svg-icons>
          </button>
          <app-svg-icons *ngIf="type === 'info' && !title" [name]="'alarm'" [customClass]="'svg-icon_colored'"></app-svg-icons>
          <app-svg-icons *ngIf="type === 'success' && !title" [name]="'checked'" [customClass]="'svg-icon_colored'"></app-svg-icons>
          <app-svg-icons *ngIf="type === 'warning' && !title" [name]="'info'" [customClass]="'svg-icon_colored'"></app-svg-icons>
          <app-svg-icons *ngIf="type === 'error' && !title" [name]="'warning'" [customClass]="'svg-icon_colored'"></app-svg-icons>
          <ng-content select="[content]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #modalElement>
  <ng-content select="[element]"></ng-content>
</ng-template>
