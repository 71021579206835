import { Injectable } from '@angular/core';
import { GlobalState, GlobalStateInterface, CRMNameEnum } from '../models/global.models';
import { AuthTypeEnum } from '../models/auth.models';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  get credentialType() {
    return this.globalState.CRMName? AuthTypeEnum[this.globalState.CRMName] : AuthTypeEnum.AMO;
  }

  /* page variables */

  public crm: CRMNameEnum;
  public globalState: GlobalState;
  // public crm: CRMName;

  // public baseUrl: string;

  public setDefaultVariables(globalState: GlobalStateInterface): void {
    this.globalState = new GlobalState(globalState);

    // this.crm = globalState.CRMName;
    // this.baseUrl = globalState.baseUrl;
  }
}
