import { NgModule, LOCALE_ID } from '@angular/core';
import { AngularStylesComponent } from './angular-styles.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertModule, PopoverModule } from 'ngx-bootstrap';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InlineSVGModule } from 'ng-inline-svg';
import { DataTablesModule } from 'angular-datatables';

import { SvgSpriteComponent } from './svg-sprite/svg-sprite.component';
import { ButtonComponent } from './button/button.component';
import { GridComponent } from './grid/grid.component';
import { LabelsComponent } from './labels/labels.component';
import { FormComponent } from './form/form.component';
import { TabsComponent } from './tabs/tabs.component';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { PaginationComponent } from './pagination/pagination.component';
import { CommonModule } from '@angular/common';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SelectComponent } from './select/select.component';
import { InputComponent } from './input/input.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { TextareaComponent } from './textarea/textarea.component';
import { WidgetSystemMessageComponent } from './message/message.component';
import { MenuComponent } from './menu/menu.component';
import { ColorpickerComponent } from './colorpicker/colorpicker.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { PopoverComponent } from './popover/popover.component';
import { TimeSelectorComponent } from './time-selector/time-selector.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { StepperComponent } from './stepper/stepper.component';
import { LoaderComponent } from './loader/loader.component';
import { DebugToolComponent } from './debug-tool/debug-tool.component';
import { AccordeonComponent } from './accordeon/accordeon.component';
import { PriceMaskDirective } from './directives/price-mask.directive';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { CustomLoader } from './translate/custom-loader';
import { TranslateLoaderService } from '@styles/translate/translate-loader.service';
import { PropertyStatusPipe } from './pipes/property-status.pipe';

@NgModule({
  declarations: [
    AngularStylesComponent,
    SvgSpriteComponent,
    ButtonComponent,
    GridComponent,
    LabelsComponent,
    FormComponent,
    TabsComponent,
    AlertComponent,
    ModalComponent,
    PaginationComponent,
    SvgIconsComponent,
    SelectComponent,
    InputComponent,
    CheckboxComponent,
    RadioButtonComponent,
    TextareaComponent,
    WidgetSystemMessageComponent,
    MenuComponent,
    ColorpickerComponent,
    PopoverComponent,
    FormGroupComponent,
    TimeSelectorComponent,
    BreadcrumbsComponent,
    StepperComponent,
    LoaderComponent,
    DebugToolComponent,
    AccordeonComponent,
    PriceMaskDirective,
    CapitalizePipe,
    PriceMaskDirective,
    PropertyStatusPipe,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    DataTablesModule,
    NgScrollbarModule,
    NgSelectModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [TranslateLoaderService]
      },
    }),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FormsModule,
    AngularStylesComponent,
    SvgSpriteComponent,
    ButtonComponent,
    GridComponent,
    LabelsComponent,
    FormComponent,
    TabsComponent,
    AlertComponent,
    ModalComponent,
    PaginationComponent,
    SvgIconsComponent,
    InlineSVGModule,
    SelectComponent,
    InputComponent,
    CheckboxComponent,
    RadioButtonComponent,
    TextareaComponent,
    WidgetSystemMessageComponent,
    MenuComponent,
    ColorpickerComponent,
    PopoverComponent,
    PopoverModule,
    FormGroupComponent,
    TimeSelectorComponent,
    BreadcrumbsComponent,
    StepperComponent,
    ReactiveFormsModule,
    LoaderComponent,
    DebugToolComponent,
    AccordeonComponent,
    PriceMaskDirective,
    CapitalizePipe,
    TranslateModule,
    PriceMaskDirective,
    PropertyStatusPipe,

    ],

  providers: [ { provide: LOCALE_ID, useValue: 'ru' }, TranslateService],

  bootstrap: [AngularStylesComponent]
})
export class AngularStylesModule { }