import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DevToolsComponent } from './components/dev-tools/dev-tools.component';
import { StyleDocumentationComponent } from './components/style-documentation/style-documentation.component';

const routes: Routes = [
  {
    path: 'account/:crm/status-list',
    loadChildren: './angular-extended-sales-funnel/angular-extended-sales-funnel.module#AngularExtendedSalesFunnelModule'
    //  loadChildren: () => import('./angular-extended-sales-funnel/angular-extended-sales-funnel.module')
    //   .then((m: any) => m.UserModule)
  },
  {
    path: 'devtools',  component: DevToolsComponent,
  },
  {
    path: 'style-documentation', component: StyleDocumentationComponent
  },
  {
    path: '', redirectTo: '/style-documentation' , pathMatch: 'full'
  }
  // {
  //   path: 'crm', loadChildren: 'app/crm/crm.module#CrmModule'
  // },
  // {
  //   path: '', component: AngularFrontComponent, pathMatch: 'full'
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
