<ng-container *ngIf="layout === 'single'">
    <div class="radio">
        <input type="radio"
               [attr.checked]="checked"
               [attr.name]="name"
               [attr.id]="id"
               [attr.disabled]="disabled"
               [attr.required]="required"

               (click)="changeHandler($event)">
        <label [attr.for]="id">
            <ng-container *ngTemplateOutlet="radioButtonContent"></ng-container>
        </label>
    </div>
</ng-container>

<ng-container *ngIf="layout === 'list' || layout === 'inline-list'">
    <ul [ngClass]="{'check-list': layout === 'list',
                  'check-list_inline': layout === 'inline-list'}">
        <li *ngFor="let item of items; let i = index">
            <div class="radio">
                <input type="radio"
                       [checked]="item[bindValue] === value"
                       [attr.name]="name"
                       [attr.id]="id + i"
                       [attr.disabled]="disabled"
                       [attr.required]="required"
                       (click)="changeHandler(item)">
                <label [attr.for]="id + i">{{ item[bindName] }}</label>
            </div>
        </li>
    </ul>
</ng-container>

<ng-container *ngIf="layout === 'toggle' || layout === 'toggle-small' || layout === 'toggle-square'">
    <ul class="radio-toggle"
        [ngClass]="{'radio-toggle_small': layout === 'toggle-small',
                    'radio-toggle_square': layout === 'toggle-square',
                    'disabled': disabled}">
        <li *ngFor="let item of items; let i = index">
            <input type="radio"
                   [checked]="item[bindValue] === value"
                   [attr.name]="name"
                   [attr.id]="id + i"
                   [attr.disabled]="disabled"
                   [attr.required]="required"
                   (click)="changeHandler(item)">
            <label [attr.for]="id + i" class="btn btn-primary">{{ item[bindName] }}</label>
        </li>
    </ul>
</ng-container>

<ng-template #radioButtonContent>
    <ng-content></ng-content>
</ng-template>
