import { TranslateLoader } from '@ngx-translate/core';
import { TranslateLoaderService } from '@styles/translate/translate-loader.service';

export class CustomLoader implements TranslateLoader {

  constructor(private translateLoaderService: TranslateLoaderService) { }

  getTranslation(lang: string): any {
    return this.translateLoaderService.getTranslation(lang);
  }

}
