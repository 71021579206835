<ng-container>
  <form [ngClass]="{'form_mixed': layout === 'mixed'}"
        [formGroup]="basicInfoForm"
        [attr.action]="action"
        [attr.method]="method"
        [attr.id]="id">
    <ng-container *ngTemplateOutlet="formElement"></ng-container>
  </form>
</ng-container>

<ng-template #formElement>
  <ng-content></ng-content>
</ng-template>
