<h1 class="page-title">Form/Formgroup</h1>
<h2>Forms</h2>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[layout]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'mixed'</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[action]</b></td>
    <td>string</td>
    <td>any string</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[method]</b></td>
    <td>string</td>
    <td>any string</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[id]</b></td>
    <td>string</td>
    <td>any string</td>
    <td></td>
  </tr>
  <tr>
    <td colspan="4">
      Examples.<br>
      Сорян, но для копипасты примеров не будет, за всеми вариантами - полезай в исходник.<br>
      Слишком геморно было заменять все < и > для примеров.</td>
  </tr>
  </tbody>
</table>
<h3>Regular form</h3>
<app-form [action]="'#'" [method]="'post'" [id]="'form-id'">

  <app-form-group>
    <ng-container label>Формирование очереди происходит на этапе</ng-container>
    <ng-container content>
      <app-select [multiple]="false"
                  [placeholder]="'Выберите этап'"
                  [items]="stages"></app-select>
    </ng-container>
  </app-form-group>

  <app-form-group>
    <ng-container label>
      <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
        <ng-container label>Уведомление менеджеру при наступлении очереди</ng-container>
      </app-checkbox>
    </ng-container>
    <ng-container content>
      <app-input
        [type]="'text'"
        [placeholder]="'Очередь на помещение подошла, свяжитесь с клиентом.'"
        [label]="false"
      ></app-input>
    </ng-container>
  </app-form-group>

  <app-button [type]="'button'" (click)="modalHide('modal13')">Сохранить</app-button>
  <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modal13')">Отмена</app-button>

</app-form>

<h3>Mixed form</h3>
<app-form [layout]="'mixed'" [action]="'#'" [method]="'post'" [id]="'form-id'">

  <app-form-group [layout]="'inline'">
    <ng-container label>тип</ng-container>
    <ng-container content>
      <app-select [multiple]="false"
                  [placeholder]="'Выберите статус'"
                  [items]="statuses"></app-select>
    </ng-container>
  </app-form-group>

  <app-form-group [layout]="'inline'">
    <ng-container label>название</ng-container>
    <ng-container content>
      <app-input
        [type]="'text'"
        [placeholder]="'Введите название статуса'"
        [label]="false"
      ></app-input>
    </ng-container>
  </app-form-group>

  <app-form-group [layout]="'inline'">
    <ng-container label>цвет</ng-container>
    <ng-container content>
      <app-colorpicker [colors]="colors" [id]="'idColor'" [name]="'color1'"></app-colorpicker>
    </ng-container>
  </app-form-group>

  <app-form-group>
    <ng-container label>Формирование очереди происходит на этапе</ng-container>
    <ng-container content>
      <app-select [multiple]="false"
                  [placeholder]="'Выберите этап'"
                  [items]="stages"></app-select>
    </ng-container>
  </app-form-group>

  <app-button [type]="'button'" (click)="modalHide('modal14')">Создать</app-button>
  <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modal14')">Отмена</app-button>

</app-form>


<h2>Form-groups</h2>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[layout]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'inline'</b></li>
        <li><b>'double'</b></li>
        <li><b>'double-labeled'</b></li>
        <li><b>'multi'</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[size]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'small'</b></li>
        <li><b>'medium'</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td>Margin-bottom size</td>
  </tr>
   <tr>
    <td><b>[shift]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'shift'</b></li>
        <li><b>'shift-lg'</b></li>
        <li><b>'shift-double'</b></li>
        <li><b>'shift-mixed'</b></li>
        <li><b>'shift-lg-double'</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td>Margin-left size</td>
  </tr>
  <tr>
    <td><b>[id]</b></td>
    <td>string</td>
    <td>string</td>
    <td>Use for a11y. Add input's id</td>
  </tr>
  <tr>
    <td><b>[label]</b></td>
    <td>boolean</td>
    <td>
      <b>Undefined</b> - default
    </td>
    <td></td>
  </tr>
  <tr>
    <td colspan="4">
      Examples.<br>
      Сорян, но для копипасты примеров не будет, за всеми вариантами - полезай в исходник.<br>
      Слишком геморно было заменять все < и > для примеров.</td>
  </tr>
  </tbody>
</table>

<h3>Regular form-group</h3>

<app-form-group>
  <ng-container label>Формирование очереди происходит на этапе</ng-container>
  <ng-container content>
    <app-select [multiple]="false"
                [placeholder]="'Выберите этап'"
                [items]="stages"></app-select>
  </ng-container>
</app-form-group>

<h3>Inline form-group</h3>

<app-form-group [layout]="'inline'">
  <ng-container label>тип</ng-container>
  <ng-container content>
    <app-select [multiple]="false"
                [placeholder]="'Выберите статус'"
                [items]="statuses"></app-select>
  </ng-container>
</app-form-group>

<h3>Multi form-group</h3>

<app-form-group [layout]="'multi'">
  <ng-container label>Срок временной брони</ng-container>
  <ng-container content>
    <app-input [type]="'number'" [placeholder]="'Number поле'"></app-input>
    <app-select [multiple]="false"
                [placeholder]="'День'"
                [items]="term"></app-select>
  </ng-container>
</app-form-group>

<h3>Meduim margin form-group</h3>

<app-form-group [size]="'medium'">
  <ng-container label>
    <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
      <ng-container label>Уведомление менеджеру при окончании брони</ng-container>
    </app-checkbox>
  </ng-container>
  <ng-container content>
  </ng-container>
</app-form-group>

<h3>Small margin form-group</h3>

<app-form-group [size]="'small'">
  <ng-container label>
    <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
      <ng-container label>Уведомление менеджеру при окончании брони</ng-container>
    </app-checkbox>
  </ng-container>
  <ng-container content>
  </ng-container>
</app-form-group>

<h3>Shifted form-group</h3>

<app-form-group [shift]="'shift'">
  <ng-container label>
    <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
      <ng-container label>Уведомление менеджеру при окончании брони</ng-container>
    </app-checkbox>
  </ng-container>
  <ng-container content>
  </ng-container>
</app-form-group>

<h3>No label form-group</h3>

<app-form-group [label]="false">
  <ng-container content>
    <app-radio-button [layout]="'toggle-small'" [items]="items" [id]="'idRadio_tog'" [name]="'radioToggle'"></app-radio-button>
  </ng-container>
</app-form-group>
