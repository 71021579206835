<h1 class="page-title">Stages</h1>

<app-modal #modalStage [id]="'modalStage'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalStage')">Настройка этапа Контакт</a>
  <ng-container title>
    Настройка этапа Контакт
  </ng-container>
  <ng-container content>
    <app-form [action]="'#'" [method]="'post'" [id]="'form-id'" [layout]="'mixed'">

      <app-form-group [layout]="'inline'">
        <ng-container label>Статус</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
            <ng-container label>
              Запрос на бронирование помещения ответственному менеджеру
              <app-popover [placement]="'right'" [content]="'Перевод помещения в статус забронировано происходит после подтверждения ответственным менеджером автоматически сформированного запроса от менеджера с отсуствием прав бронирования. Настройте права бронирования в разделе Пользователи и права.'">
                <app-svg-icons element [name]="'help-small'"></app-svg-icons>
              </app-popover>
            </ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalStage')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalStage')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
<app-modal #modalStageChecked [id]="'modalStageChecked'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalStageChecked')">Настройка этапа Контакт c Запросом на бронирование</a>
  <ng-container title>
    Настройка этапа Контакт
  </ng-container>
  <ng-container content>
    <app-form [action]="'#'" [method]="'post'" [id]="'form-id'" [layout]="'mixed'">

      <app-form-group [layout]="'inline'">
        <ng-container label>Статус</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
          <app-popover [placement]="'right'">
            <app-svg-icons element [name]="'help-small'"></app-svg-icons>
            <ng-container content>
              <p>Вы можете создавать и редактировать дополнительные статусы в разделе <a href="#">Управление статусами</a>.</p>
            </ng-container>
          </app-popover>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'" [checked]="true">
            <ng-container label>
              Запрос на бронирование помещения ответственному менеджеру
              <app-popover [placement]="'right'" [content]="'Перевод помещения в статус забронировано происходит после подтверждения ответственным менеджером автоматически сформированного запроса от менеджера с отсуствием прав бронирования. Настройте права бронирования в разделе Пользователи и права.'">
                <app-svg-icons element [name]="'help-small'"></app-svg-icons>
              </app-popover>
            </ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-textarea [rows]="2"
                        [label]="false"
                        [placeholder]="'Прошу подтвердить бронирование помещения в сделке.'"></app-textarea>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalStageChecked')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalStageChecked')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
<app-modal #modalIpoteka [id]="'modalIpoteka'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalIpoteka')">Настройка этапа «Ипотека» - постоянная бронь</a>
  <ng-container title>Настройка этапа «Ипотека»</ng-container>
  <ng-container content>
    <app-form [layout]="'mixed'" [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group [layout]="'inline'">
        <ng-container label>Статус</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [label]="false">
        <ng-container content>
          <app-radio-button [layout]="'toggle-small'" [items]="items" [id]="'idRadio_tog'" [name]="'radioToggle'"></app-radio-button>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalIpoteka')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalIpoteka')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
<app-modal #modalIpotekaTemp [id]="'modalIpotekaTemp'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalIpotekaTemp')">Настройка этапа «Ипотека» - временная бронь</a>
  <ng-container title>Настройка этапа «Ипотека»</ng-container>
  <ng-container content>
    <app-form [layout]="'mixed'" [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group [layout]="'inline'">
        <ng-container label>Статус</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [label]="false">
        <ng-container content>
          <app-radio-button [layout]="'toggle-small'" [items]="items" [id]="'idRadio_togg'" [name]="'radioToggle'"></app-radio-button>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'multi'">
        <ng-container label>Срок временной брони</ng-container>
        <ng-container content>
          <app-input [type]="'number'" [placeholder]="'Number поле'"></app-input>
          <app-select [multiple]="false"
                      [placeholder]="'День'"
                      [items]="term"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>При окончании брони/удалении очереди переводить сделку на этап</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [size]="'medium'">
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
            <ng-container label>Уведомление менеджеру при окончании брони</ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalIpotekaTemp')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalIpotekaTemp')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
<app-modal #modalIpotekaTemp2 [id]="'modalIpotekaTemp2'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalIpotekaTemp2')">Настройка этапа «Ипотека» - временная бронь с уведомлением</a>
  <ng-container title>Настройка этапа «Ипотека»</ng-container>
  <ng-container content>
    <app-form [layout]="'mixed'" [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group [layout]="'inline'">
        <ng-container label>Статус</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [label]="false">
        <ng-container content>
          <app-radio-button [layout]="'toggle-small'" [items]="items" [id]="'idRadio_togg'" [name]="'radioToggle'"></app-radio-button>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'multi'">
        <ng-container label>Срок временной брони</ng-container>
        <ng-container content>
          <app-input [type]="'number'" [placeholder]="'Number поле'"></app-input>
          <app-select [multiple]="false"
                      [placeholder]="'День'"
                      [items]="term"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>При окончании брони/удалении очереди переводить сделку на этап</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [size]="'medium'">
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'" [checked]="true">
            <ng-container label>Уведомление менеджеру при окончании брони</ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-input [type]="'text'"
                     [placeholder]="'Бронь на помещение истекает, свяжитесь с клиентом.'"
                     [label]="false"
          ></app-input>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'multi'">
        <ng-container label>Уведомить за</ng-container>
        <ng-container content>
          <app-input [type]="'number'" [placeholder]="'Number поле'"></app-input>
          <app-select [multiple]="false"
                      [placeholder]="'День'"
                      [items]="term"></app-select>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalIpotekaTemp2')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalIpotekaTemp2')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
<app-modal #modalSold [id]="'modalSold'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalSold')">Настройка этапа «Продано»</a>
  <ng-container title>Настройка этапа «Продано»</ng-container>
  <ng-container content>
    <app-form [layout]="'mixed'" [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group [layout]="'inline'">
        <ng-container label>Статус</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalSold')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalSold')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
