import { Component } from '@angular/core';

@Component({
  selector: 'app-angular-styles',
  templateUrl: './angular-styles.component.html',
  styleUrls: ['./angular-styles.component.less']
})
export class AngularStylesComponent {
  title = 'angular-main';
}
