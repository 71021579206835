<ng-container>
  <ul class="colorpicker list-unstyled" #colorpicker [ngClass]="{'form-control_error': showError}">
    <li *ngFor="let color of colors; let i = index"
        class="colorpicker__item"
        >
      <input type="radio"
             [attr.name]="name"
             [attr.id]="id + i"
             (click)="clickHandler(color)"
             [value]="color"
             [attr.checked]="color === value ? true : null"/>
      <label [attr.for]="id + i"
             [ngStyle]="{'background-color': color }">
        <app-svg-icons [name]="'checkbox'"></app-svg-icons>
      </label>
    </li>
  </ul>
</ng-container>
