import { TimeSelectorTerm, TimeSelectorTermInterface } from 'src/angular-styles/src/angular-styles/models/term.model';

export class QueueReserveSettings {
  id: number;
  crmPipelineId: string;
  active: boolean;
  automaticQueue: boolean;
  manualQueueTerm: TimeSelectorTerm;
  moveDealToStatusId: number | string;
  taskTurnQueueEnabled: boolean;
  taskTurnQueueText: string;
  clearQueueEnabled: boolean;
  clearQueueStatusId:  number | string;
  notificClearQueueEnabled: boolean;
  notificClearQueueText: string;
  clearQueue = true;

  constructor(data?: QueueReserveSettingsInterface) {
    if (data) {
      this.id = Number(data.id);
      this.crmPipelineId = data.crmPipelineId;
      this.active = !!data.active;
      this.automaticQueue = !!data.automaticQueue;
      if (data.moveDealToStatusId) {
        this.moveDealToStatusId = data.moveDealToStatusId;
      }
      this.taskTurnQueueEnabled = !!data.taskTurnQueueEnabled;
      this.taskTurnQueueText = data.taskTurnQueueText;
      this.clearQueueEnabled = !!data.clearQueueEnabled;
      if (data.clearQueueStatusId) {
        this.clearQueueStatusId = data.clearQueueStatusId;
      }
      this.notificClearQueueEnabled = !!data.notificClearQueueEnabled;
      this.notificClearQueueText = data.notificClearQueueText;
      // this.clearQueue = !!data.clearQueue;
  
      this.manualQueueTerm = new TimeSelectorTerm(data.manualQueueTerm);
    }
  }
}

export interface QueueReserveSettingsInterface {
  id: number;
  crmPipelineId: string;
  active: boolean;
  automaticQueue: boolean;
  moveDealToStatusId: number | string;
  manualQueueTerm: TimeSelectorTermInterface;
  taskTurnQueueEnabled: boolean;
  taskTurnQueueText: string;
  clearQueueEnabled: boolean;
  clearQueueStatusId: number | string;
  notificClearQueueEnabled: boolean;
  notificClearQueueText: string;
  clearQueue?: boolean;
}

