import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.less']
})
export class FormComponent implements OnInit {
  // isCollapsed: boolean;
  @Input() layout: string;
  @Input() method: string;
  @Input() action: string;
  @Input() id: string;

  basicInfoForm: FormGroup = new FormGroup({
  superName: new FormControl("", [Validators.required]),
  } )

  constructor() { }

  ngOnInit() {
    // this.isCollapsed = false;
  }

}
