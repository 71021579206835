import {
  Component, Input, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AngularStylesModalService } from '../services/angular-styles-modal.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit, OnDestroy {


  @Input() type: string;
  @Input() title: boolean; //Проверка на наличие заголовка, заменить бы на что-то типа @ContentChild(Title) title : Title;
  @Input() size: string;
  @Input() id: string;
  @Input() forceShow: boolean;
  @Input() config = {};
  @Input() protectedClose;

  @ViewChild('staticModal', {static: true}) staticModal: ModalDirective;
  @ViewChild(BsModalRef, {static: true}) bsModalRef: BsModalRef;

  // @ContentChild(Title) title : Title;

  @Output() closeEmitter: EventEmitter<void> = new EventEmitter();
  @Output() modalDataSubject = new BehaviorSubject<any>(null);

  public show(ignoreService?: boolean) {
    const { id } = this;
    if (id && !ignoreService) {
      this.modalService.modalShow(id);
    } else {
      this.staticModal.show();
    }
  }

  public hide(ignoreService?: boolean, event?: any) {

    ignoreService = ignoreService === true || (event && !['backdrop-click', 'esc'].includes(event.dismissReason));
    const { id } = this;
    if (id && !ignoreService) {
      this.modalService.modalHide(id);
      this.closeClickHandler();
    } else {
      this.staticModal.hide();
    }
  }

  constructor(
    private ref: ChangeDetectorRef,
    private modalService: AngularStylesModalService,
    private bsModalService: BsModalService
    ) {  }

  ngOnInit() {
    if (this.id) {
      this.modalService.addModal(this);
    }
    this.bsModalService.onHidden.subscribe(m => {
      this.hide();
    });
  }

  ngOnDestroy() {
    this.modalService.removeModal(this);
  }

  closeClickHandler() {
    this.closeEmitter.emit();
  }
}
