<h1 class="page-title">Modals</h1>

<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[type]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'info'</b></li>
        <li><b>'success'</b></li>
        <li><b>'warning'</b></li>
        <li><b>'error'</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[title]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[size]</b></td>
    <td>string</td>
    <td><ul class="list-styled">
      <li><b>'small'</b></li>
      <li><b>'large'</b></li>
      <li><b>Undefined</b> - default</li>
    </ul></td>
    <td>Undefined is for medium size</td>
  </tr>
  <tr>
    <td><b>[id]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td>Required</td>
  </tr>



  <tr>
    <td><b>#element</b></td>
    <td>directive</td>
    <td>
      <pre><code>&lt;app-button element [type]="'button'" (click)="modalShow('[id]')"&gt;Button title&lt;/app-button&gt;</code></pre>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>#title</b></td>
    <td>directive</td>
    <td>
      <pre><code>&lt;ng-container title&gt;Modal title&lt;/ng-container&gt;</code></pre>
    </td>
    <td>Required if [title] is true</td>
  </tr>
  <tr>
    <td><b>#content</b></td>
    <td>directive</td>
    <td>
      <pre><code>&lt;ng-container content&gt;Modal content&lt;/ng-container&gt;</code></pre>
    </td>
    <td></td>
  </tr>
  <tr>
    <td colspan="4">
      Examples.<br>
      Сорян, но будет только один пример для копипасты, за всеми вариантами - полезай в исходник.<br>
      Слишком геморно было заменять все < и > для всех примеров.</td>
  </tr>
  <tr>
    <td colspan="2">
      <app-modal #modal1 [id]="'modal1'" [title]="true">
        <app-button element [type]="'button'" (click)="modalShow('modal1')">Обычный слой</app-button>
        <ng-container title>Подтвердите свои действия</ng-container>
        <ng-container content>
          <article class="article">
            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </article>
          <app-button [type]="'button'" (click)="modalHide('modal1')">Подтвердить</app-button>
        </ng-container>
      </app-modal>
    </td>
    <td colspan="2">
      <code>&lt;app-modal #modal1 [id]="'modal1'" [title]="true"&gt;<br>
        &lt;app-button element [type]="'button'" (click)="modalShow('modal1')"&gt;Обычный слой&lt;/app-button&gt;<br>
        &lt;ng-container title&gt;Подтвердите свои действия&lt;/ng-container&gt;<br>
        &lt;ng-container content&gt;<br>
        &lt;article class="article"&gt;<br>
        &lt;p&gt;Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&lt;/p&gt;<br>
        &lt;/article&gt;<br>
        &lt;app-button [type]="'button'" (click)="modalHide('modal1')"&gt;Подтвердить&lt;/app-button&gt;<br>
        &lt;/ng-container&gt;<br>
        &lt;/app-modal&gt;</code>
    </td>
  </tr>
  </tbody>
</table>

<br><br>
<app-modal #modal2 [id]="'modal2'" [title]="true" [size]="'small'">
  <app-button element [type]="'button'" (click)="modalShow('modal2')">Узкий слой</app-button>
  <ng-container title>Подтвердите свои действия</ng-container>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal2')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal3 [id]="'modal3'" [title]="true" [size]="'large'">
  <app-button element [type]="'button'" (click)="modalShow('modal3')">Широкий слой</app-button>
  <ng-container title>Подтвердите свои действия</ng-container>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal3')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal4 [id]="'modal4'" [title]="true" [type]="'info'">
  <app-button element [type]="'button'" (click)="modalShow('modal4')">Обычный слой с иконкой в шапке</app-button>
  <ng-container title>Подтвердите свои действия</ng-container>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal4')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal5 [id]="'modal5'" [title]="true" [type]="'success'">
  <app-button element [type]="'button'" (click)="modalShow('modal5')">Зеленый слой</app-button>
  <ng-container title>Подтвердите свои действия</ng-container>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal5')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal6 [id]="'modal6'" [title]="true" [type]="'warning'">
  <app-button element [type]="'button'" (click)="modalShow('modal6')">Желтый слой</app-button>
  <ng-container title>Подтвердите свои действия</ng-container>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal6')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal7 [id]="'modal7'" [title]="true" [type]="'error'">
  <app-button element [type]="'button'" (click)="modalShow('modal7')">Красный слой</app-button>
  <ng-container title>Подтвердите свои действия</ng-container>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal7')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal8 [id]="'modal8'">
  <app-button element [type]="'button'" (click)="modalShow('modal8')">Обычный слой без шапки</app-button>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal8')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal9 [id]="'modal9'" [type]="'info'">
  <app-button element [type]="'button'" (click)="modalShow('modal9')">Обычный слой с иконкой без шапки</app-button>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal9')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal10 [id]="'modal10'" [type]="'success'">
  <app-button element [type]="'button'" (click)="modalShow('modal10')">Зеленый слой без шапки</app-button>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal10')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal11 [id]="'modal11'" [type]="'warning'">
  <app-button element [type]="'button'" (click)="modalShow('modal11')">Желтый слой без шапки</app-button>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal11')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal12 [id]="'modal12'" [type]="'error'">
  <app-button element [type]="'button'" (click)="modalShow('modal12')">Красный слой без шапки</app-button>
  <ng-container content>
    <article class="article">
      <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modal12')">Подтвердить</app-button>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal13 [id]="'modal13'" [title]="true">
  <app-button element [type]="'button'" (click)="modalShow('modal13')">Слой с обычной формой</app-button>
  <ng-container title>Слой с обычной формой</ng-container>
  <ng-container content>
    <app-form [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group>
        <ng-container label>Формирование очереди происходит на этапе</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>При наступлении очереди переводить сделку на этап</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
            <ng-container label>Уведомление менеджеру при наступлении очереди</ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-input [type]="'text'"
                     [placeholder]="'Очередь на помещение подошла, свяжитесь с клиентом.'"
                     [label]="false"
          ></app-input>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id2'">
            <ng-container label>
              Удалить очередь на этапе:
              <app-popover [content]="'При попадании на выбранный этап воронки очередь на помещение удаляется. Вы можете настроить автоматическое уведомление менеджерам, находящимся в очереди.'" [placement]="'right'">
                <app-svg-icons element [name]="'help-small'"></app-svg-icons>
              </app-popover>
            </ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите этап'"
                      [items]="stages"></app-select>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modal13')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modal13')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br><br>
<app-modal #modal14 [id]="'modal14'" [title]="true">
  <app-button element [type]="'button'" (click)="modalShow('modal14')">Слой с mixed формой</app-button>
  <ng-container title>Слой с mixed формой</ng-container>
  <ng-container content>
    <app-form [layout]="'mixed'" [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group [layout]="'inline'">
        <ng-container label>тип</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'inline'">
        <ng-container label>название</ng-container>
        <ng-container content>
          <app-input [type]="'text'"
                     [placeholder]="'Введите название статуса'"
                     [label]="false"
          ></app-input>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'inline'">
        <ng-container label>цвет</ng-container>
        <ng-container content>
          <app-colorpicker [colors]="colors" [id]="'idColor'" [name]="'color1'"></app-colorpicker>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modal14')">Создать</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modal14')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br><br>

<h2>Loader</h2>

<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[show]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[fullscreen]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td>
      <p>Default size sets to the size of the closest relative parent</p>
    </td>
  </tr>
  <tr>
    <td>Example</td>
    <td colspan="3">&lt;app-loader [show]="showLoader"&gt;&lt;/app-loader&gt;</td>
  </tr>
  <tr>
    <td>Example fullscreen</td>
    <td colspan="3">&lt;app-loader [show]="showLoader" [fullscreen]="true"&gt;&lt;/app-loader&gt;</td>
  </tr>
  </tbody>
</table>
<app-button [type]="'button'" (click)="loaderShow()">Show/hide loader</app-button>
<div style="position: relative; margin: 20px 0; height: 200px; width: 300px; background-color: #1da7ee;">
  <app-loader [name]="'for-loader'" [show]="showLoader"></app-loader>
</div>
<div style="position: relative; margin: 20px 0; height: 200px; width: 300px; background-color: #ac2925;">
  <app-loader [name]="'for-loader'" [show]="showLoader" [color]="'red'"></app-loader>
</div>
<div style="position: relative; margin: 20px 0; height: 200px; width: 300px; background-color: #0f0f0f;">
  <app-loader [name]="'m-hub'" [show]="showLoader" [color]="'black'"></app-loader>
</div>
<div style="position: relative; margin: 20px 0; height: 200px; width: 300px; background-color: #0f0f0f;">
  <app-loader [name]="'m-hub'" [show]="showLoader" [color]="'secret'"></app-loader>
</div>
