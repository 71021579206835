<ng-container *ngIf="type !== 'number'">
    <label class="control-label" [attr.for]="id" *ngIf="label">
      <ng-content select="[label]"></ng-content>
    </label>
    <div class="form-control__container">
      <input [attr.type]="type"
             [attr.placeholder]="placeholder"
             [attr.value]="value"
             [attr.name]="name"
             [attr.id]="id"
             [attr.disabled]="disabled ? true : null"
             [attr.required]="required"
             [attr.aria-describedby]="idMessage"
             class="form-control"
             [ngModel]="value"
             (ngModelChange)="changeValue($event)"
             (blur)="onTouched()"
             [ngClass]="{'form-control_number': this.type === 'number',
                       'form-control_success': this.color === 'success',
                       'form-control_warning': this.color === 'warning',
                       'form-control_error': this.color === 'error' || showError,
                       'form-control_icon-left': this.iconLeft === true,
                       'form-control_icon-right': this.iconRight === true,
                       'form-control_disabled': this.disabled,
                       'form-control_required': this.required}"
      >
      <div *ngIf="color === 'success' || color === 'warning' || color === 'error' || showError" class="form-control__indicator"></div>
      <ng-content select="[icon]" *ngIf="color !== 'success' && color !== 'warning' && color !== 'error'"></ng-content>
    </div>
    <app-message *ngIf="message" [type]="color" [attr.id]="idMessage" [message]="message"></app-message>
</ng-container>

<ng-container *ngIf="type === 'number'">
  <div class="form-control_number-container">
    <input #numberInput="ngModel"
           [attr.type]="type"
           [attr.placeholder]="placeholder"
           [attr.name]="name"
           [attr.id]="id"
           [attr.required]="required"
           class="form-control"
           [ngModel]="value"
           [attr.disabled]="disabled ? true : null"
           (ngModelChange)="writeValue($event)"
           (blur)="onTouched()"
           [ngClass]="{'form-control_number': this.type === 'number',
                       'form-control_success': this.color === 'success',
                       'form-control_warning': this.color === 'warning',
                       'form-control_error': this.color === 'error',
                       'form-control_icon-left': this.iconLeft === true,
                       'form-control_icon-right': this.iconRight === true,
                       'form-control_disabled': disabled ? true : null,
                       'form-control_required': this.required}"
    >

    <div class="form-control__spinner">
      <button 
      class="form-control__spinner-increase" 
      [disabled]="disabled"
      (click)="valueIncrease(1)"><app-svg-icons [name]="'caret-top'"></app-svg-icons></button>
      <button 
      class="form-control__spinner-decrease"
      [disabled]="disabled"
      (click)="valueIncrease(-1)"><app-svg-icons [name]="'caret-down'"></app-svg-icons></button>
    </div>
  </div>
</ng-container>

<ng-template #inputContent>
  <ng-content></ng-content>
</ng-template>
