import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TimeSelectorTerm } from '../models/term.model';

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeSelectorComponent implements OnInit {
  @Input() value: TimeSelectorTerm;
  @Input() disabled: boolean;

  @Output() valueChange: EventEmitter<TimeSelectorTerm> = new EventEmitter();

  @Input() terms = [{name: 'Час', unit: 'h'  }, { name: 'День', unit: 'd'  }, { name: 'Неделя', unit: 'w'  }, { name: 'Месяц', unit: 'm' }];

  constructor() { }

  changeValueHandler(duration: number = this.value.duration, unit: string = this.value.unit) {
    this.valueChange.emit(new TimeSelectorTerm({duration, unit}));
  }

  ngOnInit() {
    this.value = this.value || new TimeSelectorTerm({ duration: 1, unit: 'd'});
    this.valueChange.emit(this.value);
  }
}