import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './angular-front-routing.module';
import { AngularFrontComponent } from './angular-front.component';
import { HeaderComponent } from './components/header/header.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { AngularStylesModule } from '../angular-styles/src/angular-styles/angular-styles.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { IconsComponent } from './components/icons/icons.component';
import { CommonModule, APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { DevToolsComponent } from './components/dev-tools/dev-tools.component';
import { FormElementsComponent } from './components/form-elements/form-elements.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { ModalsComponent } from './components/modals/modals.component';
import { ApiInterceptorService } from './interceptor/interceptor.service';
import { StyleDocumentationComponent } from './components/style-documentation/style-documentation.component';
import { ColorpickersComponent } from './components/colorpickers/colorpickers.component';
import { TextareasComponent } from './components/textareas/textareas.component';
import { SelectsComponent } from './components/selects/selects.component';
import { StatusesComponent } from './components/statuses/statuses.component';
import { QueuesComponent } from './components/queues/queues.component';
import { StagesComponent } from './components/stages/stages.component';
import { FormGroupsComponent } from './components/form-groups/form-groups.component';
import localeRu from '@angular/common/locales/ru';

import { TypographyComponent } from './components/typography/typography.component';
import { TablesComponent } from './components/tables/tables.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AngularFrontComponent,
    HeaderComponent,
    MainMenuComponent,
    FooterComponent,
    ButtonsComponent,
    IconsComponent,
    DevToolsComponent,
    FormElementsComponent,
    AlertsComponent,
    ModalsComponent,
    StyleDocumentationComponent,
    ColorpickersComponent,
    TextareasComponent,
    SelectsComponent,
    StatusesComponent,
    QueuesComponent,
    StagesComponent,
    FormGroupsComponent,
    TypographyComponent,
    TablesComponent,
    NavigationComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    AngularStylesModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' },
  ],
  bootstrap: [AngularFrontComponent]
})
export class AngularFrontModule { }
