import { Methods } from './methods';
import { TimeSelectorTerm } from 'src/angular-styles/src/angular-styles/models/term.model';
// export class PipelineBinding {
//     crmPipelineId: number;
//     customStatusBindings: CustomStatusBinding[];

//     constructor(data?: PipelineBindingInterface) {
//         if (data) {
//             this.crmPipelineId =  Number(data.crmPipelineId);
//             this.customStatusBindings = data.customStatusBindings.map(csb => new CustomStatusBinding(csb));
//         }
//     }
// }

export class CustomStatusBinding {
    id?: number;
    crmStatusId: any;
    customStatusId?: number;
    crmPipelineId: string;
    actions: CustomBindingAction[];
    settings: CustomBindingSetting[];

    constructor(data?: CustomStatusBindingInterface) {
        if (data) {
            this.id = data.id && Number(data.id);
            this.crmStatusId = data.crmStatusId;
            this.customStatusId = data.customStatusId && Number(data.customStatusId);
            this.crmPipelineId = data.crmPipelineId;

            this.actions = data.actions && data.actions.map(cba => new CustomBindingAction(cba)) || [];
            this.settings = data.settings && data.settings.map(cbs => new CustomBindingSetting(cbs)) || [];
        }
    }
}

export class CustomBindingSetting {
    alias: CustomBindingSettingTypeEnum;
    params: CustomStatusParam;

    constructor(data?: CustomBindingSettingInterface) {
        if (data) {
            this.alias = CustomBindingSettingTypeEnum[data.alias];
            this.params = new CustomStatusParam(data.params);
        }
    }
}

export class CustomStatusParam {
    text?: string;
    term?: CustomStatusTerm;
    crmStatusId?: any;
    temporary?: boolean;
    enabled?: boolean;

    constructor(data?: CustomStatusParamInterface) {
        if (data) {
            if (data.text) {
                this.text = data.text;
            }
            if (data.term || data.term === null) {
                this.term = new CustomStatusTerm(data.term);
            }
            if (data.crmStatusId) {
                this.crmStatusId = data.crmStatusId;
            }
            if (data.temporary || data.temporary === false) {
                this.temporary = Boolean(data.temporary);
            }

            if (data.enabled || data.enabled === false) {
                this.enabled = Boolean(data.enabled);
            }

            // Methods.setIfExist(this.text, data.text);
            // Methods.setIfExist(this.term, data.term && new CustomStatusTerm(data.term));
            // Methods.setIfExist(this.crmStatusId, String(data.crmStatusId));
            // Methods.setIfExist(this.temporary, data.temporary && Boolean(data.temporary));

            // this.term =  this.term && new CustomStatusTerm(data.term);
            // this.crmStatusId = String(data.crmStatusId);
            // this.temporary = data.temporary && Boolean(data.temporary);
        }
    }
}

export class CustomBindingAction {
    alias: CustomBindingActionEnum;
    params: CustomStatusParam;

    constructor(data?: CustomBindingActionInterface) {
        if (data) {
            this.alias = CustomBindingActionEnum[data.alias];
            this.params = new CustomStatusParam(data.params);
        }
    }
}

export class CustomStatusTerm {
    unit: string;
    duration: number;

    constructor(data?: CustomStatusTermInterface) {
        if (data) {
            this.unit = data.unit;
            this.duration = data.duration;
        }
    }
}


/* INTERFACES */

export interface PipelineBindingInterface {
    customStatusBindings: CustomStatusBindingInterface[];
}

export interface CustomStatusBindingInterface {
    id?: number;
    crmStatusId?: any;
    customStatusId?: number;
    crmPipelineId: string;

    actions?: CustomBindingActionInterface[];
    settings?: CustomBindingSettingInterface[];
}

export interface CustomBindingSettingInterface {
    alias: CustomBindingSettingTypeEnum;
    params: CustomStatusParamInterface;
}

export interface CustomStatusParamInterface {
    temporary?: boolean;
    text?: string;
    term?: CustomStatusTermInterface;
    crmStatusId?: any;
    enabled?: boolean;
}

export interface CustomBindingActionInterface {
    alias: CustomBindingActionEnum;
    params: CustomStatusParamInterface;
}

export interface CustomStatusTermInterface {
    unit: string;
    duration: number;
}

/* ENUMS */

export enum CustomBindingActionEnum {
    MOVE_DEAL_END_OF_RESERVE = 'MOVE_DEAL_END_OF_RESERVE',
    TASK_TO_DEAL_END_OF_RESERVE = 'TASK_TO_DEAL_END_OF_RESERVE',

    CLEAR_QUEUE_ON_STATUS = 'CLEAR_QUEUE_ON_STATUS',
    TASK_TO_DEAL_TURN_QUEUE = 'TASK_TO_DEAL_TURN_QUEUE',
    MGR_NOTIFICATION_REMOVE_QUEUE = 'MGR_NOTIFICATION_REMOVE_QUEUE'
}

export enum CustomBindingSettingTypeEnum {
    MGR_BOOKED_CONFIRMATION = 'MGR_BOOKED_CONFIRMATION',
    BOOKED_TYPE = 'BOOKED_TYPE',
    INDIVIDUAL_SETTING_USER = 'INDIVIDUAL_SETTING_USER',
    TURN_QUEUE_MOVE_DEAL_TO_STATUS = 'TURN_QUEUE_MOVE_DEAL_TO_STATUS',
    DELETE_QUEUE_TYPE = 'DELETE_QUEUE_TYPE',
    DELETE_PROPERTY_FROM_CLOSED_DEAL_TYPE = 'DELETE_PROPERTY_FROM_CLOSED_DEAL_TYPE'
}
