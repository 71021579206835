import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-colorpickers',
  templateUrl: './colorpickers.component.html',
  styleUrls: ['./colorpickers.component.less']
})
export class ColorpickersComponent implements OnInit {
  colors = [];
  constructor() { }

  ngOnInit() {
    this.colors = ['#A54500', '#FFB400', '#EB3235', '#CD58C1', '#8342D6', '#2E89EC',
      '#2ECAEC', '#63CBA5', '#98E000', '#E2DA1D', '#A6A6A6', '#263044'];
  }

}
