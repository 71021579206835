import { CustomDataAccessorBaseComponent } from '../baseComponents/customDataAccessorBaseComponent';
import {
  Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy,
  ViewChild, forwardRef, Optional, Self, Injector, ElementRef, InjectionToken, OnChanges, SimpleChanges, ChangeDetectorRef
} from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, NgControl, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less'],
  // changeDetection: ChangeDetectionStrategy.OnPush

  // CUSTOM DATA ACCESSOR

  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => SelectComponent),
  //     multi: true
  //   }
  // ]
})
export class SelectComponent extends CustomDataAccessorBaseComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() searchable: boolean;
  @Input() placeholder: string;
  @Input() items: any[] = [];
  @Input() groupBy: string;
  @Input() notFoundText: string;
  @Input() appendTo: string;
  @Input() value: any;
  @Input() bindValue: string;
  @Input() bindLabel = 'name';
  @Input() required = true;
  @Input() emitFirstData = true;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('single', { static: false }) singleSelector: NgModel;
  @ViewChild('multiple', { static: false }) multiSelector: NgModel;


  checkInvalid(ngModel: NgModel): boolean {
    return this.required && !this.value && ngModel && (ngModel.dirty || ngModel.touched);
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public ref: ChangeDetectorRef
  ) {
    super();
    if (ngControl) { ngControl.valueAccessor = this; }
  }

  ngOnInit() {
    // console.log(this.elRef);

    // console.log(this.injector.get<NgControl>(NgControl as any));

  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc.value) {
      if (this.bindValue) {
        const value = this.items && this.items.find(i => i[this.bindValue] === sc.value.currentValue);
        if (!value) {
          sc.value.currentValue = undefined;
          this.writeValue(undefined);
        }
      }

      this.onChange(sc.value.currentValue);
    }

    this.requiredControlHandler(sc);
  }

  public showInvalid(): void {
    // const fieldValid = this.required && !this.value;
    // if (!fieldValid) {
    //   this.
    // }
  }

  public modelChangeHandler(value: any): void {
    if (value !== this.value) {
      this.writeValue(value);
    }
  }

  public reset() {
    // tslint:disable-next-line: no-unused-expression
    this.singleSelector && this.singleSelector.reset();
    // tslint:disable-next-line: no-unused-expression
    this.multiSelector && this.multiSelector.reset();
  }

}
