 <app-form-group [layout]="'multi'">
    <ng-container label>
        <ng-content></ng-content>
    </ng-container>

    <ng-container content>
        <app-input [type]="'number'"
                   [value]="value?.duration"
                   [min]="1"
                   [max]="24"
                   [disabled]="disabled"
                   (valueChange)="changeValueHandler($event, undefined)"
        ></app-input>
        <app-select [multiple]="false"
                    [value]="value.unit"
                    [bindValue]="'unit'"
                    [disabled]="disabled"
                    (valueChange)="changeValueHandler(undefined, $event)"
                    [items]="terms"></app-select>
    </ng-container>
</app-form-group>
