<ng-container>
  <input type="checkbox" attr.id="id" class="accordeon-ie accordeon-ie__input">
  <label attr.for="id" class="accordeon-ie accordeon__title">
    {{ title }}
    <app-svg-icons [name]="'caret-down'"></app-svg-icons>
  </label>

  <details class="accordeon">
    <summary class="accordeon__title">
      {{ title }}
      <app-svg-icons [name]="'caret-down'"></app-svg-icons>
    </summary>
    <article class="article" [ngClass]="{'accordeon__list': type === 'list'}">
      <ng-container *ngTemplateOutlet="accordeonContent"></ng-container>
    </article>
  </details>
</ng-container>

<ng-template #accordeonContent>
  <ng-content></ng-content>
</ng-template>
