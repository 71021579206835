<ng-container>
  <section class="tabs">
    <ul class="tabs__list">
      <li *ngFor="let tab of aliasList" class="tabs__item">
        <span *ngIf="tab.alias === currentAlias"
              class="tabs__link tabs__link_disabled">
          {{ tab.name }}
        </span>
        <a href="javascript:;"
           *ngIf="tab.alias !== currentAlias"
           class="tabs__link">
          {{ tab.name }}
        </a>
      </li>
    </ul>
    <div class="tabs__content" [ngClass]="{'tabs__content_first': currentAlias === 'tab1'}">
      <ng-content select=[tab1] *ngIf="currentAlias === 'tab1'"></ng-content>
      <ng-content select=[tab2] *ngIf="currentAlias === 'tab2'"></ng-content>
      <ng-content select=[tab3] *ngIf="currentAlias === 'tab3'"></ng-content>
      <ng-content select=[tab4] *ngIf="currentAlias === 'tab4'"></ng-content>
      <ng-content select=[tab5] *ngIf="currentAlias === 'tab5'"></ng-content>
      <ng-content select=[tab6] *ngIf="currentAlias === 'tab6'"></ng-content>
      <ng-content select=[tab7] *ngIf="currentAlias === 'tab7'"></ng-content>
      <ng-content select=[tab8] *ngIf="currentAlias === 'tab8'"></ng-content>
      <ng-content select=[tab9] *ngIf="currentAlias === 'tab9'"></ng-content>
      <ng-content select=[tab10] *ngIf="currentAlias === 'tab10'"></ng-content>
    </div>
  </section>
</ng-container>
