import {Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { AngularStylesLoaderService } from '../services/angular-styles-loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {

  @Input() show: boolean;
  @Input() fullscreen: boolean;
  @Input() name: string;
  @Input() color: string;
  @Input() ignoreService: boolean;
  @Input() local: boolean;

  bDayList = [
    '06.03', // Оксана Дунина
    '26.04', // Профит
    '04.05', // Коля Адеев
    '24.06', // Макс Селезенев
    '17.10'  // Наташа Исакова
  ];

  constructor(
    private angularStylesLoaderService: AngularStylesLoaderService,
    private ref: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    if (!this.ignoreService) {
      this.angularStylesLoaderService.requestList
      .subscribe(res => {
        this.show = !!res.length;
        this.ref.markForCheck();
      });
    }

    if (this.local) {
      this.angularStylesLoaderService.localRequestList
      .subscribe(res => {
        this.show = !!res.length;
        this.ref.markForCheck();
      });
    }
  }

  get isBDay() {
    const day = new Date().getDate();
    const month = new Date().getMonth() + 1;
    return this.bDayList.includes([day, month].join('.'));
  }
}
