import { ControlValueAccessor, NgControl } from '@angular/forms';
import { EventEmitter, Input, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';

export abstract class CustomDataAccessorBaseComponent implements ControlValueAccessor {
  abstract value: any;
  abstract valueChange: EventEmitter<any>;
  abstract ngControl: NgControl;
  abstract required: boolean;
  abstract ref: ChangeDetectorRef;

  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  @Input() requiredControl: boolean;
  @Output() requiredControlEmitter = new EventEmitter<boolean>();

  requiredControlHandler(sc: SimpleChanges) {
    if (sc.requiredControl) {
      if (sc.requiredControl.currentValue) {
        this.requiredControlEmitter.emit(true);
      } else {
        this.requiredControlEmitter.emit(false);
      }
    }
    this.ref.markForCheck();
  }

  get showError() {
    if (this.ngControl) {
      const { touched, invalid } = this.ngControl;
      return touched && invalid && this.required;

    }
  }

  writeValue(value: any): void {
    if (value !== null) {
      this.value = value;
      this.valueChange.emit(value);
      this.onChange(value);
      this.onTouched();
      this.ref.markForCheck();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.ref.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
  }

  onChange = (value) => {
  }

  onTouched = () => {
  }
}
