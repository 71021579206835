<app-breadcrumbs [aliasList]="['Style documentation', 'navigation']"
                 [currentAlias]="'navigation'"
                 (aliasEmitter)="menuClickHandler($event)"></app-breadcrumbs>
<h1 class="page-title">Navigations</h1>

<h2>Breadcrumbs</h2>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[aliasList]</b></td>
    <td>array</td>
    <td>['Style documentation', 'navigation', ...]</td>
    <td>Array of links, from parent to child</td>
  </tr>
  <tr>
    <td><b>[currentAlias]</b></td>
    <td>string</td>
    <td>any string</td>
    <td>Name of the current page</td>
  </tr>
  <tr>
    <td colspan="4">
      Examples.
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <br>
      <app-breadcrumbs [aliasList]="['Style documentation', 'navigation']"
                       [currentAlias]="'navigation'"
                       (aliasEmitter)="menuClickHandler($event)"></app-breadcrumbs>
    </td>
    <td colspan="2">
      <code>&lt;app-breadcrumbs [aliasList]="['Style documentation', 'navigation']" [currentAlias]="'step 1'" (aliasEmitter)="menuClickHandler($event)"&gt;&lt;/app-breadcrumbs&gt;</code>
    </td>
  </tr>
  </tbody>
</table>

<h2>Accordeon</h2>
<table class="table table-striped">
  <thead>
  <tr>
    <th width="300">Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[title]</b></td>
    <td>string</td>
    <td>any string</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[id]</b></td>
    <td>string</td>
    <td>any string</td>
    <td>For IE and Edge</td>
  </tr>
  <tr>
    <td><b>[type]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'list'</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td>Used for unstyled list like list of users</td>
  </tr>
  <tr>
    <td colspan="4">
      Examples.
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-accordeon [id]="'accordion-id'" [type]="'list'" [title]="'Всегда постоянная бронь (2)'">
        <ul class="list-unstyled">
          <li><a href="#">Егор Глебов - g.egorov@profitbase.ru</a></li>
          <li><a href="#">Егор Глебов - g.egorov@profitbase.ru</a></li>
        </ul>
      </app-accordeon>
    </td>
    <td colspan="2">
      <code>
        &lt;app-accordeon [id]="'accordion-id'" [type]="'list'" [title]="'Всегда постоянная бронь (2)'"&gt;
          &lt;ul class="list-unstyled"&gt;
            &lt;li&gt;&lt;a href="#"&gt;Егор Глебов - g.egorov@profitbase.ru&lt;/a&gt;&lt;/li&gt;
            &lt;li&gt;&lt;a href="#"&gt;Егор Глебов - g.egorov@profitbase.ru&lt;/a&gt;&lt;/li&gt;
          &lt;/ul&gt;
        &lt;/app-accordeon&gt;
      </code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-accordeon [id]="'accordion-id'" [title]="'Why? said Harry.'">
        <p>"She said Dumbledore's got his reasons," said Ron, shaking his head darkly.</p>
        <p>"I suppose we've got to trust him, haven't we?" The only person apart from Ron and Hermione that Harry felt able to talk to was Hagrid.</p>
        <p>As there was no longer a Defense Against the Dark Arts teacher, they had those lessons free.</p>
        <p>They used the one on Thursday afternoon to go down and visit Hagrid in his cabin.</p>
        <p>It was a bright and sunny day; Fang bounded out of the open door as they approached, barking and wagging his tail madly.</p>
        <p>"Who's that?" called Hagrid, coming to the door. "Harry!" He strode out to meet them,
          pulled Harry into a one-armed hug, ruffled his hair, and said, "Good ter see yeh, mate. Good ter see yeh."
          They saw two bucket-size cups and saucers on the wooden table in front of the fireplace when they entered
          Hagrid's cabin. "Bin havin' a cuppa with Olympe," Hagrid said. "She's jus' left." "Who?" said Ron curiously.
          "Madame Maxime, o' course!" said Hagrid. "You two made up, have you?" said Ron. "Dunno what yeh're talkin'
          about," said Hagrid airily, fetching more cups from the dresser. When he had made tea and offered around
          a plate of doughy cookies, he leaned back in his chair and surveyed Harry closely through his beetle-black
          eyes. "You all righ'?" he said gruffly
        </p>
      </app-accordeon>
    </td>
    <td colspan="2">
      <code>
        &lt;app-accordeon [id]="'accordion-id'" [title]="'Why? said Harry.'"&gt;
        &lt;p&gt;"She said Dumbledore's got his reasons," said Ron, shaking his head darkly.&lt;/p&gt;
        &lt;p&gt;"I suppose we've got to trust him, haven't we?" The only person apart from Ron and Hermione that Harry felt able to talk to was Hagrid.&lt;/p&gt;
        &lt;p&gt;As there was no longer a Defense Against the Dark Arts teacher, they had those lessons free.&lt;/p&gt;
        &lt;p&gt;They used the one on Thursday afternoon to go down and visit Hagrid in his cabin.&lt;/p&gt;
        &lt;p&gt;It was a bright and sunny day; Fang bounded out of the open door as they approached, barking and wagging his tail madly.&lt;/p&gt;
        &lt;p&gt;"Who's that?" called Hagrid, coming to the door. "Harry!" He strode out to meet them,
          pulled Harry into a one-armed hug, ruffled his hair, and said, "Good ter see yeh, mate. Good ter see yeh."
          They saw two bucket-size cups and saucers on the wooden table in front of the fireplace when they entered
          Hagrid's cabin. "Bin havin' a cuppa with Olympe," Hagrid said. "She's jus' left." "Who?" said Ron curiously.
          "Madame Maxime, o' course!" said Hagrid. "You two made up, have you?" said Ron. "Dunno what yeh're talkin'
          about," said Hagrid airily, fetching more cups from the dresser. When he had made tea and offered around
          a plate of doughy cookies, he leaned back in his chair and surveyed Harry closely through his beetle-black
          eyes. "You all righ'?" he said gruffly
        &lt;/p&gt;
        &lt;/app-accordeon&gt;
      </code>
    </td>
  </tr>
  </tbody>
</table>

<h2>Stepper</h2>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[aliasList]</b></td>
    <td>objects array</td>
    <td>
      [{{ '{' }} name: 'step 1', enabled: true {{ '}' }}, ... {{ '{' }} name: 'step 4', enabled: false {{ '}' }}]
    </td>
    <td>Array of steps, from left to right</td>
  </tr>
  <tr>
    <td><b>[currentAlias]</b></td>
    <td>string</td>
    <td>any string</td>
    <td>Name of the current step</td>
  </tr>
  <tr>
    <td colspan="4">
      Examples.
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-stepper [aliasList]="aliasList" [currentAlias]="'step 3'"></app-stepper>
    </td>
    <td colspan="2">
      <code>&lt;app-stepper [aliasList]="aliasList" [currentAlias]="'step 3'"&gt;&lt;/app-stepper&gt;</code>
    </td>
  </tr>
  </tbody>
</table>


<h2>Tabs</h2>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[aliasList]</b></td>
    <td>objects array</td>
    <td>
      [{{ '{' }} name: 'Tab 1', alias: 'tab1' {{ '}' }}, ... {{ '{' }} name: 'Tab 3', alias: 'tab3' {{ '}' }}]
    </td>
    <td>Array of tabs, from left to right</td>
  </tr>
  <tr>
    <td><b>[currentAlias]</b></td>
    <td>string</td>
    <td>any string</td>
    <td>Name of the current tab</td>
  </tr>
  <tr>
    <td><b>#tab1..10</b></td>
    <td>directive</td>
    <td>alias from [aliasList]</td>
    <td>Content of the tab, 10 tabs maximum</td>
  </tr>
  <tr>
    <td colspan="4">
      Examples.
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-tabs [aliasList]="tabsList" [currentAlias]="'tab2'">
        <ng-container tab1>123</ng-container>
        <ng-container tab2>456</ng-container>
      </app-tabs>
    </td>
    <td colspan="2">
      <code>&lt;app-tabs [aliasList]="tabsList" [currentAlias]="'tab2'"&gt;<br>
        &lt;ng-container tab1&gt;123&lt;ng-container&gt;<br>
        &lt;ng-container tab2&gt;456&lt;ng-container&gt;<br>
        &lt;/app-stepper&gt;</code>
    </td>
  </tr>
  </tbody>
</table>


<h2>Pagination</h2>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[pages]</b></td>
    <td>array</td>
    <td>['#link1', '#link2', '#link3', '#link4', '#link5']</td>
    <td>Array of links, from first to last page</td>
  </tr>
  <tr>
    <td><b>[currentPage]</b></td>
    <td>number</td>
    <td>any number</td>
    <td>Number of the current page</td>
  </tr>
  <tr>
    <td colspan="4">
      Examples.
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-pagination [pages]="pages" [currentPage]="2"></app-pagination>
    </td>
    <td colspan="2">
      <code>&lt;app-pagination [pages]="pages" [currentPage]="2"&gt;&lt;/app-pagination&gt;</code>
    </td>
  </tr>
  </tbody>
</table>

