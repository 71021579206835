<h1 class="page-title">Icons</h1>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[name]</b></td>
    <td>string</td>
    <td>any name from list below</td>
    <td>Required, no default value!</td>
  </tr>
  <tr>
    <td><b>[inline]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b> - default</li>
        <li><b>false</b></li>
      </ul>
    </td>
    <td>For use as <b>&lt;img&gt;</b> set to <b>false</b></td>
  </tr>
  <tr>
    <td><b>[customClass]</b></td>
    <td>string</td>
    <td>any string</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[alt]</b></td>
    <td>string</td>
    <td>any string</td>
    <td>For use as <b>&lt;img&gt;</b></td>
  </tr>
  <tr>
    <td><b>[height]</b></td>
    <td>number</td>
    <td>any number, default is 32</td>
    <td>For use as <b>&lt;img&gt;</b></td>
  </tr>
  <tr>
    <td><b>[width]</b></td>
    <td>number</td>
    <td>any number, default is 32</td>
    <td>For use as <b>&lt;img&gt;</b></td>
  </tr>
  <tr>
    <td colspan="4">Icons with svg-gradients cannot be used inline. Use them as <b>&lt;img&gt;</b> or as a background-image (loader-sm, logo-pb, logo-unisender, etc)</td>
  </tr>
  <tr>
    <td>Example inline</td>
    <td colspan="3">
      <code>&lt;app-svg-icons [name]="'3D'" [customClass]="'some-class'"&gt;&lt;/app-svg-icons&gt;</code>
    </td>
  </tr>
  <tr>
    <td>Example as <b>&lt;img&gt;</b></td>
    <td colspan="3">
      <code>&lt;app-svg-icons [name]="'logo-pb'" [inline]="false" [alt]="'Some alt'" [height]="75" [width]="75"&gt;&lt;/app-svg-icons&gt;</code>
    </td>
  </tr>
  </tbody>
</table>
<h2>Icons list</h2>
<ul class="icons__list">
  <li><app-svg-icons [name]="'3D'" [customClass]="'some-class'"></app-svg-icons><code>&lt;app-svg-icons [name]="'3D'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'add'" [customClass]="'some-class'"></app-svg-icons><code>&lt;app-svg-icons [name]="'add'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'agent'"></app-svg-icons><code>&lt;app-svg-icons [name]="'agent'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'alarm'"></app-svg-icons><code>&lt;app-svg-icons [name]="'alarm'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'amo'"></app-svg-icons><code>&lt;app-svg-icons [name]="'amo'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'analitics'"></app-svg-icons><code>&lt;app-svg-icons [name]="'analitics'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'api'"></app-svg-icons><code>&lt;app-svg-icons [name]="'api'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'apps'"></app-svg-icons><code>&lt;app-svg-icons [name]="'apps'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'arrow'"></app-svg-icons><code>&lt;app-svg-icons [name]="'arrow'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'arrow-round-l'"></app-svg-icons><code>&lt;app-svg-icons [name]="'arrow-round-l'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'arrow-round-r'"></app-svg-icons><code>&lt;app-svg-icons [name]="'arrow-round-r'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'avito'"></app-svg-icons><code>&lt;app-svg-icons [name]="'avito'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'back'"></app-svg-icons><code>&lt;app-svg-icons [name]="'back'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'banknotes'"></app-svg-icons><code>&lt;app-svg-icons [name]="'banknotes'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'bitrix'"></app-svg-icons><code>&lt;app-svg-icons [name]="'bitrix'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'block'"></app-svg-icons><code>&lt;app-svg-icons [name]="'block'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'buildings'"></app-svg-icons><code>&lt;app-svg-icons [name]="'buildings'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'calendar'"></app-svg-icons><code>&lt;app-svg-icons [name]="'calendar'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'camera'"></app-svg-icons><code>&lt;app-svg-icons [name]="'camera'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'cancel'"></app-svg-icons><code>&lt;app-svg-icons [name]="'cancel'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'caret-down'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-down'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li style="background-color: #1da7ee"><app-svg-icons [name]="'caret-down-white'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-down-white'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'caret-left'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-left'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'caret-right'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-right'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'caret-thin-bottom'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-thin-bottom'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'caret-thin-left'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-thin-left'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'caret-thin-right'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-thin-right'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'caret-thin-top'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-thin-top'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'caret-top'"></app-svg-icons><code>&lt;app-svg-icons [name]="'caret-top'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'catalog'"></app-svg-icons><code>&lt;app-svg-icons [name]="'catalog'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'check'"></app-svg-icons><code>&lt;app-svg-icons [name]="'check'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'check-round'"></app-svg-icons><code>&lt;app-svg-icons [name]="'check-round'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li style="background-color: #1da7ee"><app-svg-icons [name]="'check-white'"></app-svg-icons><code>&lt;app-svg-icons [name]="'check-white'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li style="background-color: #1da7ee"><app-svg-icons [name]="'checkbox'"></app-svg-icons><code>&lt;app-svg-icons [name]="'checkbox'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'checked'"></app-svg-icons><code>&lt;app-svg-icons [name]="'checked'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'cian'"></app-svg-icons><code>&lt;app-svg-icons [name]="'cian'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'clock'"></app-svg-icons><code>&lt;app-svg-icons [name]="'clock'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'close'"></app-svg-icons><code>&lt;app-svg-icons [name]="'close'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'cms'"></app-svg-icons><code>&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'code'"></app-svg-icons><code>&lt;app-svg-icons [name]="'code'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'copy'"></app-svg-icons><code>&lt;app-svg-icons [name]="'copy'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'cross'"></app-svg-icons><code>&lt;app-svg-icons [name]="'cross'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'deals'"></app-svg-icons><code>&lt;app-svg-icons [name]="'deals'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'decrease'"></app-svg-icons><code>&lt;app-svg-icons [name]="'decrease'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'delete'"></app-svg-icons><code>&lt;app-svg-icons [name]="'delete'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'diamond'"></app-svg-icons><code>&lt;app-svg-icons [name]="'diamond'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'directions'"></app-svg-icons><code>&lt;app-svg-icons [name]="'directions'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'domclick'"></app-svg-icons><code>&lt;app-svg-icons [name]="'domclick'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'download'"></app-svg-icons><code>&lt;app-svg-icons [name]="'download'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'drag'"></app-svg-icons><code>&lt;app-svg-icons [name]="'drag'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'draggable'"></app-svg-icons><code>&lt;app-svg-icons [name]="'draggable'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'dropdown'"></app-svg-icons><code>&lt;app-svg-icons [name]="'dropdown'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'edit'"></app-svg-icons><code>&lt;app-svg-icons [name]="'edit'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'envelope'"></app-svg-icons><code>&lt;app-svg-icons [name]="'envelope'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'error-note'"></app-svg-icons><code>&lt;app-svg-icons [name]="'error-note'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'excel'"></app-svg-icons><code>&lt;app-svg-icons [name]="'excel'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'expand'"></app-svg-icons><code>&lt;app-svg-icons [name]="'expand'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'eye'"></app-svg-icons><code>&lt;app-svg-icons [name]="'eye'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'favourite'"></app-svg-icons><code>&lt;app-svg-icons [name]="'favourite'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'feed'"></app-svg-icons><code>&lt;app-svg-icons [name]="'feed'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'file-download'"></app-svg-icons><code>&lt;app-svg-icons [name]="'file-download'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'filter'"></app-svg-icons><code>&lt;app-svg-icons [name]="'filter'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'fire'"></app-svg-icons><code>&lt;app-svg-icons [name]="'fire'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'folder'"></app-svg-icons><code>&lt;app-svg-icons [name]="'folder'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'for-loader'"></app-svg-icons><code>&lt;app-svg-icons [name]="'for-loader'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'gear'"></app-svg-icons><code>&lt;app-svg-icons [name]="'gear'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'gift'"></app-svg-icons><code>&lt;app-svg-icons [name]="'gift'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'grab'"></app-svg-icons><code>&lt;app-svg-icons [name]="'grab'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'graphic'"></app-svg-icons><code>&lt;app-svg-icons [name]="'graphic'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'header-back'"></app-svg-icons><code>&lt;app-svg-icons [name]="'header-back'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'header-close'"></app-svg-icons><code>&lt;app-svg-icons [name]="'header-close'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'header-home'"></app-svg-icons><code>&lt;app-svg-icons [name]="'header-home'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'heart'"></app-svg-icons><code>&lt;app-svg-icons [name]="'heart'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'help'"></app-svg-icons><code>&lt;app-svg-icons [name]="'help'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'help-small'"></app-svg-icons><code>&lt;app-svg-icons [name]="'help-small'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'home'"></app-svg-icons><code>&lt;app-svg-icons [name]="'home'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'inbox'"></app-svg-icons><code>&lt;app-svg-icons [name]="'inbox'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'increase'"></app-svg-icons><code>&lt;app-svg-icons [name]="'increase'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'info'"></app-svg-icons><code>&lt;app-svg-icons [name]="'info'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'info-man'"></app-svg-icons><code>&lt;app-svg-icons [name]="'info-man'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'interactive'"></app-svg-icons><code>&lt;app-svg-icons [name]="'interactive'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'job'"></app-svg-icons><code>&lt;app-svg-icons [name]="'job'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'licence'"></app-svg-icons><code>&lt;app-svg-icons [name]="'licence'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'liked-img'"></app-svg-icons><code>&lt;app-svg-icons [name]="'liked-img'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'link'"></app-svg-icons><code>&lt;app-svg-icons [name]="'link'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'list'"></app-svg-icons><code>&lt;app-svg-icons [name]="'list'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'loader-sm'" [inline]="false" [alt]="'Some alt'" [height]="75" [width]="150"></app-svg-icons><code>&lt;app-svg-icons [name]="'loader-sm'" [inline]="false" [alt]="'Some alt'" [height]="75" [width]="150"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'loading'"></app-svg-icons><code>&lt;app-svg-icons [name]="'loading'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'location'"></app-svg-icons><code>&lt;app-svg-icons [name]="'location'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-cian'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-cian'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-comagic'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-comagic'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-domclick'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-domclick'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-domclick2'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-domclick2'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-g-adw'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-g-adw'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-mailchimp'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-mailchimp'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-pb'" [inline]="false" [alt]="'Some alt'" [height]="75" [width]="150"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-pb'" [inline]="false" [alt]="'Some alt'" [height]="75" [width]="150"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-unisender'" [inline]="false" [alt]="'Some alt'" [height]="75" [width]="150"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-unisender'" [inline]="false" [alt]="'Some alt'" [height]="75" [width]="150"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-xs'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-xs'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-ya'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-ya'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logo-ya-audience'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logo-ya-audience'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logout'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logout'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'logout2'"></app-svg-icons><code>&lt;app-svg-icons [name]="'logout2'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'lpgenerator'"></app-svg-icons><code>&lt;app-svg-icons [name]="'lpgenerator'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'m-hub'"></app-svg-icons><code>&lt;app-svg-icons [name]="'m-hub'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'mail'"></app-svg-icons><code>&lt;app-svg-icons [name]="'mail'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'manual'"></app-svg-icons><code>&lt;app-svg-icons [name]="'manual'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'menu'"></app-svg-icons><code>&lt;app-svg-icons [name]="'menu'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'message'"></app-svg-icons><code>&lt;app-svg-icons [name]="'message'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'mobile'"></app-svg-icons><code>&lt;app-svg-icons [name]="'mobile'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'my_agent'"></app-svg-icons><code>&lt;app-svg-icons [name]="'my_agent'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'my_developer'"></app-svg-icons><code>&lt;app-svg-icons [name]="'my_developer'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'my_manual'"></app-svg-icons><code>&lt;app-svg-icons [name]="'my_manual'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'my_object'"></app-svg-icons><code>&lt;app-svg-icons [name]="'my_object'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'my_order'"></app-svg-icons><code>&lt;app-svg-icons [name]="'my_order'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'non-eye'"></app-svg-icons><code>&lt;app-svg-icons [name]="'non-eye'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'objects_create'"></app-svg-icons><code>&lt;app-svg-icons [name]="'objects_create'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'objects_editor'"></app-svg-icons><code>&lt;app-svg-icons [name]="'objects_editor'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'objects_history'"></app-svg-icons><code>&lt;app-svg-icons [name]="'objects_history'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'objects_journal'"></app-svg-icons><code>&lt;app-svg-icons [name]="'objects_journal'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'objects_list'"></app-svg-icons><code>&lt;app-svg-icons [name]="'objects_list'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'objects_special'"></app-svg-icons><code>&lt;app-svg-icons [name]="'objects_special'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'order'"></app-svg-icons><code>&lt;app-svg-icons [name]="'order'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'outbox'"></app-svg-icons><code>&lt;app-svg-icons [name]="'outbox'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'payments'"></app-svg-icons><code>&lt;app-svg-icons [name]="'payments'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'pb-loader'"></app-svg-icons><code>&lt;app-svg-icons [name]="'pb-loader'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'percent'"></app-svg-icons><code>&lt;app-svg-icons [name]="'percent'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'photo'"></app-svg-icons><code>&lt;app-svg-icons [name]="'photo'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'pologo'"></app-svg-icons><code>&lt;app-svg-icons [name]="'pologo'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'price'"></app-svg-icons><code>&lt;app-svg-icons [name]="'price'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'print'"></app-svg-icons><code>&lt;app-svg-icons [name]="'print'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'printer'"></app-svg-icons><code>&lt;app-svg-icons [name]="'printer'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'profile'"></app-svg-icons><code>&lt;app-svg-icons [name]="'profile'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'profitmax'"></app-svg-icons><code>&lt;app-svg-icons [name]="'profitmax'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'project'"></app-svg-icons><code>&lt;app-svg-icons [name]="'project'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'projects'"></app-svg-icons><code>&lt;app-svg-icons [name]="'projects'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'queue'"></app-svg-icons><code>&lt;app-svg-icons [name]="'queue'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'queue-clear'"></app-svg-icons><code>&lt;app-svg-icons [name]="'queue-clear'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'rarr'"></app-svg-icons><code>&lt;app-svg-icons [name]="'rarr'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'rotate'"></app-svg-icons><code>&lt;app-svg-icons [name]="'rotate'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'sberbank'"></app-svg-icons><code>&lt;app-svg-icons [name]="'sberbank'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'sberbank-green'"></app-svg-icons><code>&lt;app-svg-icons [name]="'sberbank-green'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'sberbank-outline'"></app-svg-icons><code>&lt;app-svg-icons [name]="'sberbank-outline'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li style="background-color: #1da7ee"><app-svg-icons [name]="'sberbank-white'"></app-svg-icons><code>&lt;app-svg-icons [name]="'sberbank-white'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'scenario'"></app-svg-icons><code>&lt;app-svg-icons [name]="'scenario'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'search'"></app-svg-icons><code>&lt;app-svg-icons [name]="'search'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'segment'"></app-svg-icons><code>&lt;app-svg-icons [name]="'segment'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'service'"></app-svg-icons><code>&lt;app-svg-icons [name]="'service'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'settings'"></app-svg-icons><code>&lt;app-svg-icons [name]="'settings'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'share'"></app-svg-icons><code>&lt;app-svg-icons [name]="'share'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'shopping'"></app-svg-icons><code>&lt;app-svg-icons [name]="'shopping'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'smart'"></app-svg-icons><code>&lt;app-svg-icons [name]="'smart'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'smiley'"></app-svg-icons><code>&lt;app-svg-icons [name]="'smiley'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'sprocket'"></app-svg-icons><code>&lt;app-svg-icons [name]="'sprocket'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'star'"></app-svg-icons><code>&lt;app-svg-icons [name]="'star'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'star-img'"></app-svg-icons><code>&lt;app-svg-icons [name]="'star-img'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'start'"></app-svg-icons><code>&lt;app-svg-icons [name]="'start'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'support'"></app-svg-icons><code>&lt;app-svg-icons [name]="'support'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'tariff'"></app-svg-icons><code>&lt;app-svg-icons [name]="'tariff'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'technology'"></app-svg-icons><code>&lt;app-svg-icons [name]="'technology'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'thumbnails'"></app-svg-icons><code>&lt;app-svg-icons [name]="'thumbnails'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'time'"></app-svg-icons><code>&lt;app-svg-icons [name]="'time'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'time-machine'"></app-svg-icons><code>&lt;app-svg-icons [name]="'time-machine'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'touch'"></app-svg-icons><code>&lt;app-svg-icons [name]="'touch'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'tracking'"></app-svg-icons><code>&lt;app-svg-icons [name]="'tracking'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'trash'"></app-svg-icons><code>&lt;app-svg-icons [name]="'trash'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'undo'"></app-svg-icons><code>&lt;app-svg-icons [name]="'undo'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'update'"></app-svg-icons><code>&lt;app-svg-icons [name]="'update'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'user'"></app-svg-icons><code>&lt;app-svg-icons [name]="'user'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'users'"></app-svg-icons><code>&lt;app-svg-icons [name]="'users'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'video'"></app-svg-icons><code>&lt;app-svg-icons [name]="'video'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'view-3d'"></app-svg-icons><code>&lt;app-svg-icons [name]="'view-3d'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'view-facade'"></app-svg-icons><code>&lt;app-svg-icons [name]="'view-facade'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'view-floor-plan'"></app-svg-icons><code>&lt;app-svg-icons [name]="'view-floor-plan'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'view-list'"></app-svg-icons><code>&lt;app-svg-icons [name]="'view-list'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'view-middle'"></app-svg-icons><code>&lt;app-svg-icons [name]="'view-middle'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'view-plans'"></app-svg-icons><code>&lt;app-svg-icons [name]="'view-plans'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'view-small'"></app-svg-icons><code>&lt;app-svg-icons [name]="'view-small'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'wallet'"></app-svg-icons><code>&lt;app-svg-icons [name]="'wallet'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'warning'"></app-svg-icons><code>&lt;app-svg-icons [name]="'warning'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'widget-block'"></app-svg-icons><code>&lt;app-svg-icons [name]="'widget-block'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'widget-building'"></app-svg-icons><code>&lt;app-svg-icons [name]="'widget-building'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'widget-calendar'"></app-svg-icons><code>&lt;app-svg-icons [name]="'widget-calendar'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'widget-calendar-gray'"></app-svg-icons><code>&lt;app-svg-icons [name]="'widget-calendar-gray'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'widget-check'"></app-svg-icons><code>&lt;app-svg-icons [name]="'widget-check'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'widget-close'"></app-svg-icons><code>&lt;app-svg-icons [name]="'widget-close'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'widget-info'"></app-svg-icons><code>&lt;app-svg-icons [name]="'widget-info'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'widget-search'"></app-svg-icons><code>&lt;app-svg-icons [name]="'widget-search'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'xml'"></app-svg-icons><code>&lt;app-svg-icons [name]="'xml'"&gt;&lt;/app-svg-icons&gt;</code></li>
  <li><app-svg-icons [name]="'yandex'"></app-svg-icons><code>&lt;app-svg-icons [name]="'yandex'"&gt;&lt;/app-svg-icons&gt;</code></li>
</ul>
