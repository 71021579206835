import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { StateService } from 'src/angular-front/services/state.service';
import { State, ViewEnum } from 'src/angular-front/models/state.models';
import { BaseComponent } from '../../../angular-styles/src/angular-styles/baseComponents/baseComponent.class';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-style-documentation',
  templateUrl: './style-documentation.component.html',
  styleUrls: ['./style-documentation.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StyleDocumentationComponent extends BaseComponent implements OnInit {

  title = 'angular-front';
  currentAlias: string;

  get view(): Observable<ViewEnum> {
    return this.state.view;
  }

  menuClickHandler(alias: string) {
    const view = ViewEnum[alias];
    this.stateService.setState({view});
    this.currentAlias = view;
  }

  get state(): State {
    return this.stateService.state;
  }

  constructor(private stateService: StateService,
  public ref: ChangeDetectorRef) { super(); }

  ngOnInit() {
    // this.addSubscriber('viewSubscriber', this.state.view
    //   .subscribe(view => this.view = view));
  }
}
