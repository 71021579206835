import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy,
OnChanges, Self, Optional, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CustomDataAccessorBaseComponent } from '../baseComponents/customDataAccessorBaseComponent';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends CustomDataAccessorBaseComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() type = 'checkbox';
  @Input() checked: boolean;
  @Input() layout = 'single';
  @Input() icon: string;
  @Input() name: string;
  @Input() id: string;
  @Input() required: boolean;
  @Input() color: string;
  @Input() message: boolean = false;
  @Input() messageType: string;
  @Input() items: [{}] = [{}];
  @Input() value: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();


  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public ref: ChangeDetectorRef
  ) {
    super();
    if (ngControl) { ngControl.valueAccessor = this; }
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc.value) {
      this.onChange(sc.value.currentValue);
    }

    this.requiredControlHandler(sc);
  }

  ngOnInit() {
    // this.valueChanges.emit(!!this.checked && this.value);
  }

  changeHandler(event: any): void {
    const checked = event.target && event.target.checked;
    this.checked = !!event;
    // this.valueChanges.emit(checked && this.value);
    this.writeValue(checked);
  }

}
