import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {
  @Input() aliasList: string[];
  @Input() currentAlias: string;
  @Output() aliasEmitter: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  clickHandler(alias: string): void {
    this.aliasEmitter.emit(alias);
  }

}
