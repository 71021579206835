<ng-container>
  <ul class="stepper__list">
    <li *ngFor="let alias of aliasList, let i = index"
        class="stepper__item"
        [ngClass]="{'stepper__item_disabled': alias.enabled === false}">
      <a href="javascript:;"
         class="stepper__link"
         *ngIf="alias.enabled === true && alias.name !== currentAlias">
        <span class="stepper__counter">{{ i + 1 }}</span> {{ alias.name }}
      </a>
      <span class="stepper__link stepper__link_disabled"
            *ngIf="alias.enabled === false || alias.name === currentAlias">
        <span class="stepper__counter">{{ i + 1 }}</span> {{ alias.name }}
      </span>
    </li>
  </ul>
</ng-container>

