export interface GlobalStateInterface {
  CRMName?: CRMNameEnum;
  baseUrl?: string;
  pbApiKey?: string;
}

export class GlobalState {
  readonly CRMName: CRMNameEnum;
  readonly baseUrl: string;
  readonly pbApiKey: string;

  constructor(data?: GlobalStateInterface) {
    this.CRMName = data.CRMName;
    this.baseUrl = data.baseUrl;
    this.pbApiKey = data.pbApiKey;
  }
}

export enum CRMNameEnum {
  BITRIX = 'BITRIX',
  AMO = 'AMO',
  CREATIO = 'CREATIO',
  SBER = 'SBER'
}
