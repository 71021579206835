<h1 class="page-title">Textareas</h1>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[placeholder]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[rows]</b></td>
    <td>number</td>
    <td>any number value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[color]</b></td>
    <td>string</td>
    <td>
      <ul class="list-styled">
        <li><b>'success'</b></li>
        <li><b>'warning'</b></li>
        <li><b>'error'</b></li>
        <li><b>Undefined</b> - default</li>
      </ul>
    </td>
    <td>undefined is for regular color</td>
  </tr>
  <tr>
    <td><b>[message]</b></td>
    <td>string</td>
    <td>any string</td>
    <td>
      The [color] of message is the same as [color] of textarea.<br>
      If other [color] is needed - use separate app-message instead of [message]
    </td>
  </tr>
  <tr>
    <td><b>[name]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[id]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[resize]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b> - default</li>
        <li><b>false</b></li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[disabled]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[required]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[label]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b> - default</li>
        <li><b>false</b></li>
      </ul>
    </td>
    <td>Use to turn off #label</td>
  </tr>
  <tr>
    <td><b>#label</b></td>
    <td>directive</td>
    <td colspan="2">
      <pre><code>&lt;ng-container label&gt;Some label&lt;/ng-container&gt;</code></pre>
    </td>
  </tr>
  <tr>
    <td colspan="4">Examples</td>
  </tr>
  <tr>
    <td colspan="2">
      <app-textarea [placeholder]="'Textarea...'">
        <ng-container label>Common textarea</ng-container>
      </app-textarea>
    </td>
    <td colspan="2">
      <code>&lt;app-textarea [placeholder]="'Textarea...'"&gt;<br>
        &lt;ng-container label&gt;Common textarea&lt;/ng-container&gt;<br>
        &lt;/app-textarea&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-textarea [placeholder]="'Textarea...'" [rows]="2">
        <ng-container label>Common textarea 2 rows</ng-container>
      </app-textarea>
    </td>
    <td colspan="2">
      <code>&lt;app-textarea [placeholder]="'Textarea...'" [rows]="2"&gt;<br>
        &lt;ng-container label&gt;Common textarea 2 rows&lt;/ng-container&gt;<br>
        &lt;/app-textarea&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-textarea [placeholder]="'Textarea...'" [resize]="false">
        <ng-container label>Common textarea no resize</ng-container>
      </app-textarea>
    </td>
    <td colspan="2">
      <code>&lt;app-textarea [placeholder]="'Textarea...'" [resize]="false"&gt;<br>
        &lt;ng-container label&gt;Common textarea no resize&lt;/ng-container&gt;<br>
        &lt;/app-textarea&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-textarea [placeholder]="'Textarea...'" [disabled]="true">
        <ng-container label>Disabled textarea</ng-container>
      </app-textarea>
    </td>
    <td colspan="2">
      <code>&lt;app-textarea [placeholder]="'Textarea...'" [disabled]="true"&gt;<br>
        &lt;ng-container label&gt;Disabled textarea&lt;/ng-container&gt;<br>
        &lt;/app-textarea&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-textarea [placeholder]="'Textarea...'" [color]="'success'">
        <ng-container label>Success textarea</ng-container>
      </app-textarea>
    </td>
    <td colspan="2">
      <code>&lt;app-textarea [placeholder]="'Textarea...'" [color]="'success'"&gt;<br>
        &lt;ng-container label&gt;Success textarea&lt;/ng-container&gt;<br>
        &lt;/app-textarea&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-textarea [placeholder]="'Textarea...'" [color]="'warning'">
        <ng-container label>Warning textarea</ng-container>
      </app-textarea>
    </td>
    <td colspan="2">
      <code>&lt;app-textarea [placeholder]="'Textarea...'" [color]="'warning'"&gt;<br>
        &lt;ng-container label&gt;Warning textarea&lt;/ng-container&gt;<br>
        &lt;/app-textarea&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-textarea [placeholder]="'Textarea...'" [color]="'error'">
        <ng-container label>Error textarea</ng-container>
      </app-textarea>
    </td>
    <td colspan="2">
      <code>&lt;app-textarea [placeholder]="'Textarea...'" [color]="'error'"&gt;<br>
        &lt;ng-container label&gt;Error textarea&lt;/ng-container&gt;<br>
        &lt;/app-textarea&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-textarea [placeholder]="'Error textarea with message and no label'"
                    [label]="false"
                    [color]="'error'"
                    [message]="'Some message'">
      </app-textarea>
    </td>
    <td colspan="2">
      <code>&lt;app-textarea [placeholder]="'Error textarea with message and no label'"
        [label]="false"
        [color]="'error'"
        [message]="'Some message'"&gt;&lt;/app-textarea&gt;</code>
    </td>
  </tr>
  </tbody>
</table>
