import { Component, OnInit } from '@angular/core';
import {
  CustomStatusTerm
} from "../../angular-extended-sales-funnel/models/pipeline-binding.model";
import { UnitEnum } from 'src/angular-styles/src/angular-styles/models/term.model';

@Component({
  selector: 'app-selects',
  templateUrl: './selects.component.html',
  styleUrls: ['./selects.component.less']
})
export class SelectsComponent implements OnInit {
  items = [];
  emptyItems = [];

  unit: UnitEnum;
  duration: number;
  term = new CustomStatusTerm({ unit: 'm', duration: 12});

  constructor() {}


  ngOnInit() {
    this.items = [
      { name: 'option 1', groupName: 'some group'},
      { name: 'option 2'},
      { name: 'option 3'},
      { name: 'option 4'},
      { name: 'option 5'},
      { name: 'option 6', groupName: 'some group'},
      { name: 'option 7', groupName: 'some other group'},
      { name: 'option 8', groupName: 'some other group'}
    ];
    // this.emptyItems;
  }

}


