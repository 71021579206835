<h1 class="page-title">Кнопки</h1>

<table class="table table-striped">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Values</th>
      <th>Comment</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>[type]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'button'</b></li>
          <li><b>'submit'</b></li>
          <li><b>'reset'</b></li>
          <li><b>'link'</b></li>
        </ul>
      </td>
      <td>Required, no default value!</td>
    </tr>
    <tr>
      <td><b>[color]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'primary'</b> - default</li>
          <li><b>'brand'</b></li>
          <li><b>'white'</b></li>
          <li><b>'transparent'</b></li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>[outline]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td>Only for 'primary' and 'brand' colors</td>
    </tr>
    <tr>
      <td><b>[size]</b></td>
      <td>string</td>
      <td>
        <ul class="list-styled">
          <li><b>'small'</b></li>
          <li><b>Undefined</b> - default</li>
        </ul>
      </td>
      <td>Undefined is for regular size</td>
    </tr>
    <tr>
      <td><b>[gradient]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td>works for 'primary' and 'brand' [color] only</td>
    </tr>
    <tr>
      <td><b>[onlyIcon]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>[disabled]</b></td>
      <td>boolean</td>
      <td>
        <ul class="list-styled">
          <li><b>true</b></li>
          <li><b>false</b> - default</li>
        </ul>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>[href]</b></td>
      <td>string</td>
      <td>any href</td>
      <td>for 'link' type only</td>
    </tr>
    <tr>
      <td><b>[ariaLabel]</b></td>
      <td>string</td>
      <td>any string value</td>
      <td>For a11y, use with [onlyIcon]</td>
    </tr>
    <tr>
      <td colspan="4">
        <b>NB! For buttons with text AND icon: add a span around text for proper margins around icon.</b>
      </td>
    </tr>
    <tr>
      <td colspan="4">Examples <b>[type]="button"</b></td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'">Button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'"&gt;Button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [size]="'small'">Small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [size]="'small'"&gt;Small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [outline]="true">Outlined button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [outline]="true"&gt;Outlined button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [size]="'small'" [outline]="true">Outlined small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [size]="'small'" [outline]="true"&gt;Outlined small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'">Brand button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'"&gt;Brand button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [size]="'small'">Brand small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [size]="'small'"&gt;Brand small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [outline]="true">Brand outline button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [outline]="true"&gt;Brand outline button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [size]="'small'" [outline]="true">Brand outline small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [size]="'small'" [outline]="true"&gt;Brand outline small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'white'">White button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'white'"&gt;White button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'white'" [size]="'small'">White small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'white'" [size]="'small'"&gt;White small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [gradient]="true">Gradient button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [gradient]="true"&gt;Gradient button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [gradient]="true">Gradient brand button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [gradient]="true"&gt;Gradient brand button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'transparent'">Transparent button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'transparent'"&gt;Transparent button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'transparent'" [disabled]="true">Transparent disabled button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'transparent'" [disabled]="true"&gt;Transparent disabled button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true">Disabled button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [type]="'button'" [disabled]="true"&gt;Disabled button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [size]="'small'">Disabled small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [size]="'small'"&gt;Disabled small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [outline]="true">Disabled outline button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [outline]="true"&gt;Disabled outline button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [size]="'small'" [outline]="true">Disabled outline small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [size]="'small'" [outline]="true"&gt;Disabled outline small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Outlined button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Outlined button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Outlined small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Outlined small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Brand button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Brand button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Brand small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Brand small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Brand outline button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Brand outline button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Brand outline small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Brand outline small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'white'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>White button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'white'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;White button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'white'" [disabled]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>White button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'white'" [disabled]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;White button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'transparent'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Transparent button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'transparent'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Transparent button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'transparent'" [disabled]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Transparent button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'transparent'" [disabled]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Transparent button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Disabled button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Disabled button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Disabled small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Disabled small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Disabled outline button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Disabled outline button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Disabled outline small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Disabled outline small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [onlyIcon]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [onlyIcon]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [onlyIcon]="true" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [onlyIcon]="true" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [onlyIcon]="true" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [onlyIcon]="true" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [onlyIcon]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [onlyIcon]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [onlyIcon]="true" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [onlyIcon]="true" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'brand'" [onlyIcon]="true" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'brand'" [onlyIcon]="true" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'white'" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'white'" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'white'" [onlyIcon]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'white'" [onlyIcon]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'transparent'" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'transparent'" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [color]="'transparent'" [onlyIcon]="true" [disabled]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [color]="'transparent'" [onlyIcon]="true" [disabled]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [onlyIcon]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [onlyIcon]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [onlyIcon]="true" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [onlyIcon]="true" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'button'" [disabled]="true" [onlyIcon]="true" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'button'" [disabled]="true" [onlyIcon]="true" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td colspan="4">Examples <b>[type]="'link'"</b></td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'">Button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'"&gt;Button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [size]="'small'">Small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [size]="'small'"&gt;Small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [outline]="true">Outlined button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [outline]="true"&gt;Outlined button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [size]="'small'" [outline]="true">Outlined small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [size]="'small'" [outline]="true"&gt;Outlined small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'">Brand button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'"&gt;Brand button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [size]="'small'">Brand small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [size]="'small'"&gt;Brand small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [outline]="true">Brand outline button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [outline]="true"&gt;Brand outline button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [size]="'small'" [outline]="true">Brand outline small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [size]="'small'" [outline]="true"&gt;Brand outline small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'white'">White button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'white'"&gt;White button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'white'" [size]="'small'">White small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'white'" [size]="'small'"&gt;White small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [gradient]="true">Gradient button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [gradient]="true"&gt;Gradient button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [gradient]="true">Gradient brand button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [gradient]="true"&gt;Gradient brand button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'transparent'">Transparent button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'transparent'"&gt;Transparent button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'transparent'" [disabled]="true">Transparent disabled button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'transparent'" [disabled]="true"&gt;Transparent disabled button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true">Disabled button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true"&gt;Disabled button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [size]="'small'">Disabled small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [size]="'small'"&gt;Disabled small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [outline]="true">Disabled outline button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [outline]="true"&gt;Disabled outline button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [size]="'small'" [outline]="true">Disabled outline small button</app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [size]="'small'" [outline]="true"&gt;Disabled outline small button&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Outlined button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Outlined button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Outlined small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Outlined small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Brand button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Brand button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Brand small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Brand small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Brand outline button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Brand outline button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Brand outline small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Brand outline small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'white'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>White button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'white'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;White button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'white'" [disabled]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>White button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'white'" [disabled]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;White button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'transparent'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Transparent button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'transparent'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Transparent button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'transparent'" [disabled]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Transparent button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'transparent'" [disabled]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Transparent button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Disabled button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Disabled button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Disabled small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Disabled small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Disabled outline button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Disabled outline button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons><span>Disabled outline small button</span></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;span&gt;Disabled outline small button&lt;/span&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [onlyIcon]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [onlyIcon]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [onlyIcon]="true" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [onlyIcon]="true" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [onlyIcon]="true" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [onlyIcon]="true" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [onlyIcon]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [onlyIcon]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [onlyIcon]="true" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [onlyIcon]="true" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'brand'" [onlyIcon]="true" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'brand'" [onlyIcon]="true" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'white'" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'white'" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'white'" [onlyIcon]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'white'" [onlyIcon]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'transparent'" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'transparent'" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [color]="'transparent'" [onlyIcon]="true" [disabled]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [color]="'transparent'" [onlyIcon]="true" [disabled]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [onlyIcon]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [onlyIcon]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [onlyIcon]="true" [size]="'small'"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [onlyIcon]="true" [size]="'small'"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [onlyIcon]="true" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [onlyIcon]="true" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
    <tr>
      <td>
        <app-button [type]="'link'" [href]="'#'" [disabled]="true" [onlyIcon]="true" [size]="'small'" [outline]="true"><app-svg-icons [name]="'cms'"></app-svg-icons></app-button>
      </td>
      <td colspan="3">
        <code>&lt;app-button [type]="'link'" [href]="'#'" [disabled]="true" [onlyIcon]="true" [size]="'small'" [outline]="true"&gt;&lt;app-svg-icons [name]="'cms'"&gt;&lt;/app-svg-icons&gt;&lt;/app-button&gt;</code>
      </td>
    </tr>
  </tbody>
</table>
