import { TimeSelectorTerm, TimeSelectorTermInterface } from 'src/angular-styles/src/angular-styles/models/term.model';

export interface CrmIndividualSettingsInterface {
  id: number;
  fullName: string;
  email: string;
  queueSettings: CrmIndividualQueueSettingsInterface;
  reserveSettings: CrmIndividualReserveSettingsTermInterface;
  url: string;
}

export interface CrmIndividualQueueSettingsInterface {
  isQueueManager: boolean;
}

export class CrmIndividualQueueSettings {
    isQueueManager: boolean;

    constructor(data?: CrmIndividualQueueSettingsInterface) {
      if (data) {
        this.isQueueManager = Boolean(data.isQueueManager);
      }
    }
}

export interface CrmIndividualReserveSettingsTermInterface {
  hasIndividualSetting: boolean;
  temporaryReserve: boolean;
  canReservation: boolean;
  term: TimeSelectorTermInterface;
}

export class CrmIndividualReserveSettings {
  hasIndividualSetting: boolean;
  temporaryReserve: boolean;
  term: TimeSelectorTerm;
  canReservation: boolean;

  constructor(data?: CrmIndividualReserveSettingsTermInterface) {
    if (data) {
      if (data.hasIndividualSetting !== null) {
        this.hasIndividualSetting = Boolean(data.hasIndividualSetting);
      }

      if (data.temporaryReserve !== null) {
        this.temporaryReserve = Boolean(data.temporaryReserve);
      }
      
      this.temporaryReserve = Boolean(data.temporaryReserve);
      this.term = data.term && new TimeSelectorTerm(data.term);
      this.canReservation = !!data.canReservation;
    }
  }
}

export class CrmIndividualSettings {
  id: number;
  fullName: string;
  email: string;
  queueSettings: CrmIndividualQueueSettings;
  reserveSettings: CrmIndividualReserveSettings;
  url: string;

  constructor(data?: CrmIndividualSettingsInterface) {
    if (data) {
      this.id = Number(data.id);
      this.fullName = data.fullName;
      this.email = data.email;
      this.queueSettings = new CrmIndividualQueueSettings(data.queueSettings);
      this.reserveSettings = new CrmIndividualReserveSettings(data.reserveSettings);
      this.url = data.url;
    }
  }
}