<ng-container>
  <div class="alert"
       [ngClass]="{'alert_iconed': type,
                   'alert_success': type === 'success' || type === 'success-info',
                   'alert_warning': type === 'warning',
                   'alert_error': type === 'error',
                   'alert_info': type === 'info'}">
    <a *ngIf="hidable" href="javascript:;" class="alert__hide">
      <app-svg-icons [name]="'delete'"></app-svg-icons>
    </a>
    <div *ngIf="type" class="alert__icon" [ngClass]="{'alert__icon_titled': title}">
      <app-svg-icons *ngIf="type === 'info'" [name]="'alarm'"></app-svg-icons>
      <app-svg-icons *ngIf="type === 'success'" [name]="'checked'"></app-svg-icons>
      <app-svg-icons *ngIf="type === 'warning'" [name]="'info'"></app-svg-icons>
      <app-svg-icons *ngIf="type === 'success-info'" [name]="'info'"></app-svg-icons>
      <app-svg-icons *ngIf="type === 'error'" [name]="'warning'"></app-svg-icons>
    </div>
    <div>
      <h3 *ngIf="title" class="alert__title">{{ title | translate }}</h3>
      <ng-container *ngTemplateOutlet="alertContent"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #alertContent>
  <ng-content></ng-content>
</ng-template>
