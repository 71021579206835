import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, Optional, Self } from '@angular/core';
import { CustomDataAccessorBaseComponent } from '../baseComponents/customDataAccessorBaseComponent';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonComponent extends CustomDataAccessorBaseComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() checked: boolean;
  @Input() layout: string;
  @Input() name: string;
  @Input() id: string;
  @Input() required: boolean;
  @Input() items: [{}] = [{}];
  @Input() value: any;
  @Input() bindValue = 'value';
  @Input() bindName = 'name';

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public ref: ChangeDetectorRef
  ) {
    super();
  }

  changeHandler(item: any): void {
    const value = this.bindValue && item[this.bindValue] !== undefined ? item[this.bindValue] : item;
    this.writeValue(value);
  }

  ngOnInit() {
    // this.valueChanges.emit(this.value);
  }
}
