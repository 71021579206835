<ng-container *ngIf="content">
    <span
      class="popover_plain"
      popover="{{content}}"
      popoverTitle="{{title}}"
      placement="{{placement}}"
      triggers="mouseenter:mouseleave"
    >

      <ng-container *ngTemplateOutlet="tooltipElement"></ng-container>

    </span>
  </ng-container>

  <ng-container *ngIf="!content">
    <ng-template #popTemplate>

      <ng-content select="[content]"></ng-content>

    </ng-template>
      <span
        [popover]="popTemplate"
        popoverTitle="{{title}}"
        outsideClick="true"
        placement="{{placement}}">

          <ng-container *ngTemplateOutlet="tooltipElement"></ng-container>

      </span>
  </ng-container>

  <ng-template #tooltipElement>
    <ng-content select="[element]"></ng-content>
  </ng-template>




