<ng-container *ngIf="layout === 'single'">
 <!-- checked:  {{ checked | json }}
 value:  {{ value | json }} -->

  <!--setCheckboxClasses()-->
  <!---->
  <div [ngClass]="{'switch': type === 'switch',
                   'checkbox': type === 'checkbox' || type === 'icon',
                   'icon': type === 'icon',
                   'switch_success': type === 'switch' && color === 'success',
                   'switch_warning': type === 'switch' && color === 'warning',
                   'switch_error': type === 'switch' && color === 'error'}">
    <input [attr.type]="'checkbox'"
           [attr.checked]="checked || value ? true : null"
           [attr.name]="name"
           [attr.id]="id"
           [attr.disabled]="disabled"
           [attr.required]="required"
           (click)="changeHandler($event)"
    >
    <label [attr.for]="id" *ngIf="type !== 'icon'">
      <app-svg-icons *ngIf="type === 'checkbox'"
                     [name]="'checkbox'"
                     [customClass]="'svg-icon_check'"></app-svg-icons>
      <ng-content select="[label]"></ng-content>
      <!--<ng-container *ngTemplateOutlet="checkboxContent"></ng-container>-->
    </label>
    <label [attr.for]="id" *ngIf="type === 'icon'">
      <app-svg-icons [name]="icon" [customClass]="'svg-icon_check'"></app-svg-icons>
      <div role="tooltip" class="popover in popover-bottom bs-popover-bottom bottom bs3">
        <div class="popover-arrow arrow"></div>
        <div class="popover-content popover-body">{{name}}</div>
      </div>
    </label>
    <app-message *ngIf="message" [type]="messageType">
      <ng-content select="[message]"></ng-content>
    </app-message>
  </div>
</ng-container>
           <!-- [(ngModel)]="value"
           (ngModelChange)="changeHandler($event, $target)" -->
<ng-container *ngIf="layout === 'list' || layout === 'inline-list'">
  <ul [ngClass]="{'check-list': type === 'checkbox',
                  'switch-list': type === 'switch',
                  'check-list_inline': type === 'checkbox' && layout === 'inline-list',
                  'switch-list_inline': type === 'switch' && layout === 'inline-list'}">
    <li *ngFor="let item of items; let i = index">
      <div [attr.class]="type">
        <input [attr.type]="'checkbox'"
               [attr.checked]="item?.checked ? true : null"
               [attr.name]="name"
               [attr.id]="id + i"
               [attr.disabled]="disabled"
               [attr.required]="required"
               (click)="changeHandler($event)"
               >
        <label [attr.for]="id + i">
          <app-svg-icons *ngIf="type === 'checkbox'" [name]="'checkbox'" [customClass]="'svg-icon_check'"></app-svg-icons>
          {{ item.name }}
        </label>
      </div>
    </li>
  </ul>
</ng-container>

<ng-template #checkboxContent>
  <ng-content></ng-content>
</ng-template>
