<ng-container *ngIf="multiple === false">
  <ng-select #single="ngModel"
             [items]="items"
             [ngClass]="{'form-control_error': showError }"
             [groupBy]="groupBy"
             [disabled]="disabled || !items?.length > 0"
             [ngModel]="value"
             [searchable]="searchable"
             [multiple]="false"
             [clearable]="false"
             [placeholder]="placeholder"
             [notFoundText]="notFoundText"
             [appendTo]="appendTo"
             bindValue="{{ bindValue }}"
             bindLabel="{{ bindLabel }}"
             (blur)="onTouched()"
             [virtualScroll]="true"
             (ngModelChange)="modelChangeHandler($event)">
    <ng-template ng-optgroup-tmp let-item="item" *ngIf="groupBy">
      <div *ngIf="checkInvalid(single)" class="form-control__indicator"></div>
      {{ item[groupBy] || groupBy }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div *ngIf="checkInvalid(single)" class="form-control__indicator"></div>
      {{ item.name }}
    </ng-template>
  </ng-select>
</ng-container>

<ng-container *ngIf="multiple === true">
  <ng-select #multiple="ngModel"
             [items]="items"
             [ngClass]="{'form-control_error': checkInvalid(multiple)}"
             [groupBy]="groupBy"
             [disabled]="disabled || !items?.length > 0"
             [ngModel]="value"
             (ngModelChange)="modelChangeHandler($event)"
             [searchable]="searchable"
             [multiple]="true"
             [closeOnSelect]="false"
             [clearable]="false"
             bindLabel="{{ bindLabel }}"
             [placeholder]="placeholder"
             [virtualScroll]="true"
             [notFoundText]="notFoundText">
    <ng-template ng-optgroup-tmp let-item="item" *ngIf="groupBy">
      {{ item[groupBy] || groupBy }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      {{ item.name }}
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <span class="ng-value-label">{{ item.name }}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </ng-template>
  </ng-select>
</ng-container>
