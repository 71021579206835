export enum BaseStatusEnum {
  AVAILABLE = 'AVAILABLE',
  BOOKED = 'BOOKED',
  SOLD = 'SOLD',
  UNAVAILABLE = 'UNAVAILABLE'
}

export interface CustomStatusInterface {
  id?: number;
  name: string;
  color: string;
  baseStatus: string;
  isProtected: boolean;
}

export class CustomStatus {
  id?: number;
  name: string;
  color: string;
  baseStatus: BaseStatusEnum;
  isProtected: boolean;

  constructor(data?: CustomStatusInterface) {
    if (data) {
      this.id = data.id && Number(data.id);
      this.name = data.name;
      this.color = data.color;
      this.baseStatus = BaseStatusEnum[data.baseStatus];
      this.isProtected = !!data.isProtected;

    }
  }
}

