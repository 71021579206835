import { Component, OnInit } from '@angular/core';
import {AngularStylesModalService} from "../../../angular-styles/src/angular-styles/services/angular-styles-modal.service";

@Component({
  selector: 'app-queues',
  templateUrl: './queues.component.html',
  styleUrls: ['./queues.component.less']
})
export class QueuesComponent implements OnInit {

  stages = [];
  constructor(
    private modalService: AngularStylesModalService
  ) { }

  ngOnInit() {
    this.stages = [{name: 'Потребности выявлены'}, {name: 'Устная бронь'}, {name: 'Продано'}];
  }

  modalShow(id: string) {
    this.modalService.modalShow(id);
  }

  modalHide(id: string) {
    this.modalService.modalHide(id);
  }
}
