import {EventEmitter, Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AngularStylesLoaderService {
  requestListSource = new BehaviorSubject<string[]>([]);
  localRequestListSource = new BehaviorSubject<string[]>([]);

  get requestList() {
    return this.requestListSource.asObservable();
  }

  get localRequestList() {
    return this.localRequestListSource.asObservable();
  }

  constructor() { }

  addLoader(reqAlias: string) {
    this.requestListSource.next(this.requestListSource.getValue().concat(reqAlias));
  }

  removeLoader(reqAlias: string) {
    this.requestListSource.next(this.requestListSource.getValue().filter(r => r !== reqAlias));
  }

  addLocalLoader(reqAlias: string) {
    this.localRequestListSource.next(this.localRequestListSource.getValue().concat(reqAlias));
  }

  removeLocalLoader(reqAlias: string) {
    this.localRequestListSource.next(this.localRequestListSource.getValue().filter(r => r !== reqAlias));
  }

}
