import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AuthResponse } from '../models/auth.models';
import { GlobalService } from '../services/global.service';
import { FunnelResponse, FunnelResponseInterface } from '../angular-extended-sales-funnel/models/response.model';

export enum InterceptAlias {
  Intercept = 'Intercept',
  NoIntercept = 'NoIntercept',
  Auth = 'Auth'
}

export enum AuthErrors {
  'Not allowed' = 'Not allowed',
  'expired_token' = 'expired_token',
  'Not allowed.' = 'Not allowed.',
}

@Injectable({
  providedIn: 'root'
})

export class ApiInterceptorService implements HttpInterceptor {
  public static apiEndpoint: string;
  private _mockPath = './assets/mock-data';

  constructor(
    private authService: AuthService,
    private globalService: GlobalService
  ) { }


  private get baseUrl(): string {
    return this.globalService.globalState.baseUrl;
  }

  private createHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
  }

  private createUrl(path): string {
    // if (ApiInterceptorService.apiEndpoint) {
    //   return ApiInterceptorService.apiEndpoint + '/' + path;
    // } else {
    return `${this.baseUrl}/api/v4/json/${path}`;
    // return this._mockPath + '/' + path + '.json';
    // }
  }

  private interceptHandler(req: HttpRequest<any>, next: HttpHandler): Observable<FunnelResponse | any> {
    const parts = req.url.split('/');
    if (parts.length > 0 &&
      [
        'funnel',
        'pipelines',
        'custom-status',
        'binding',
        'queue-reserve',
        'crm',
        'crm-individual-settings',
        'reserve-setting',
        'visit-event-status',
        'dictionary',
        'online-booking',
      ]
        .some(reqAlias => parts.includes(reqAlias))) {
      return this.sendRequestAfterAuth(req, next);
    }

    if (parts.length > 0 &&
      parts.includes('authentication')) {
      return this.sendAuthRequest(req, next);
    }

    return next.handle(req);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<FunnelResponse | any> {
    return this.interceptHandler(req, next).pipe(catchError(err => {
      if (err.error === AuthErrors['Not allowed'] || err.error && err.error.error === AuthErrors.expired_token) {
        this.authService.resetAuthToken();
        return this.interceptHandler(req, next);
      }
    }));
  }

  private sendRequest(clonedRequest: HttpRequest<any>, next: HttpHandler): Observable<FunnelResponse | any> {
    return next.handle(clonedRequest)
      .pipe(map((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
        //   const data: FunnelResponseInterface = event.body;
        //   event.body =  new FunnelResponse(data);
        //   return event;
        // }
        return event;
      }))
      // .pipe(catchError((err, caught) => this.errorHandler(err, caught, clonedRequest, next)));
  }

  private errorHandler(err: any, caught: Observable<any>, clonedRequest?: HttpRequest<any>, next?: HttpHandler)
    : Observable<FunnelResponseInterface> {
    if (err.status === 403 && (err.msg === 'Not allowed.' || err.error === 'expired_token'
     || err.error ===  'Not allowed.') && clonedRequest) {
      return this.authService.getAuth()
        .pipe(switchMap(_ => this.sendRequestAfterAuth(clonedRequest, next)))
        .pipe(catchError(err => throwError(new Error('auth error'))));
    } else {
      return of(new FunnelResponse(null, err));
    }
  }

  private sendRequestAfterAuth(req: HttpRequest<any>, next: HttpHandler): Observable<FunnelResponse> {
    return this.authService.authResponse
      .pipe(
        switchMap((authResponse: AuthResponse) => {

          const headers = this.createHeader();
          const url = this.createUrl(req.url);
          let params = req.params || new HttpParams();

          params = params.append('access_token', authResponse.accessToken);

          const update = {
            headers,
            url,
            params
          };

          const clonedRequest = req.clone(update);
          return this.sendRequest(clonedRequest, next);
        }))
      // .pipe(catchError((err, caught) => this.errorHandler(err, caught, null, next)));

  }

  private sendAuthRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const params = {
      headers: this.createHeader(),
      url: this.createUrl(req.url)
    };

    const clonedRequest = req.clone(params);
    return next.handle(clonedRequest);
  }
}
