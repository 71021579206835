<ng-container>
  <div class="help-block"
       [ngClass]="{'help-block_success': type === 'success',
                   'help-block_warning': type === 'warning',
                   'help-block_error': type === 'error'}">
    {{ message }}
    <ng-container *ngTemplateOutlet="messageContent"></ng-container>
  </div>
</ng-container>

<ng-template #messageContent>
  <ng-content></ng-content>
</ng-template>
