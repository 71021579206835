<ng-container>
  <ul class="breadcrumbs__list">
    <li class="breadcrumbs__item" *ngFor="let alias of aliasList">
      <ng-container *ngIf="alias !== currentAlias">
        <a href="javascript:;" class="breadcrumbs__link" (click)="clickHandler(alias)">{{alias}}</a>
        <app-svg-icons [name]="'caret-right'"></app-svg-icons>
      </ng-container>
      <span *ngIf="alias === currentAlias" class="breadcrumbs__link breadcrumbs__link_current">{{alias}}</span>
    </li>
  </ul>
</ng-container>
