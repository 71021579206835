import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from './global.service';
import { PageEnum } from '../models/state.models';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(private globalService: GlobalService, private router: Router) { }

  private _activatedRoute: ActivatedRoute;

  public get activatedRouter(): ActivatedRoute {
    return this._activatedRoute;
  }

  public setActivatedRoute(activatedRoute: ActivatedRoute) {
    this._activatedRoute = activatedRoute;
  }

  changeRoute(path: PageEnum) {
    this.router.navigate([`/${path}`]);
  }
}
