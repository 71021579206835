import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-groups',
  templateUrl: './form-groups.component.html',
  styleUrls: ['./form-groups.component.less']
})
export class FormGroupsComponent implements OnInit {

  statuses = [];
  stages = [];
  colors = [];
  items = [];
  term = [];
  constructor() { }

  ngOnInit() {
    this.statuses = [{name: 'Свободно'}, {name: 'Забронировано'}, {name: 'Продано'}];
    this.stages = [{name: 'Потребности выявлены'}, {name: 'Устная бронь'}, {name: 'Продано'}];
    this.colors = ['#A54500', '#FFB400', '#EB3235', '#CD58C1', '#8342D6', '#2E89EC',
      '#2ECAEC', '#63CBA5', '#98E000', '#E2DA1D', '#A6A6A6', '#263044'];
    this.items = [{name: 'Постоянно'}, {name: 'Временно'}];
    this.term = [{name: 'День'}, {name: 'Неделя'}, {name: 'Месяц'}];
  }

  public modalHide(e: any) {

  }

}
