<ng-container>
  <label class="control-label" [attr.for]="id" *ngIf="label">
    <ng-content select="[label]"></ng-content>
  </label>
  <textarea [attr.name]="name"
            [attr.id]="id"
            [attr.disabled]="disabled"
            [attr.required]="required"
            [attr.placeholder]="placeholder"
            [attr.rows]="rows"
            [ngModel]="value"
            (blur)="onTouched()"
            (ngModelChange)="valueChangeHandler($event)"
            class="form-control"
            [ngClass]="{'form-control_success': color === 'success',
                        'form-control_error': color === 'error' || showError,
                        'form-control_warning': color === 'warning',
                        'no-resize': resize === false }"></textarea>
  <app-message *ngIf="message" [type]="color" [message]="message"></app-message>
</ng-container>
