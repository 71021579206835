import { Component, OnInit } from '@angular/core';
import {AngularStylesModalService} from "../../../angular-styles/src/angular-styles/services/angular-styles-modal.service";

@Component({
  selector: 'app-statuses',
  templateUrl: './statuses.component.html',
  styleUrls: ['./statuses.component.less']
})
export class StatusesComponent implements OnInit {

  items = [];
  statuses = [];
  term = [];
  colors = [];
  customStatuses = [{}];

  constructor(
    private modalService: AngularStylesModalService
  ) { }

  ngOnInit() {
    this.statuses = [{name: 'Свободно'}, {name: 'Забронировано'}, {name: 'Продано'}];
    this.items = [{name: 'Постоянно'}, {name: 'Временно'}];
    this.term = [{name: 'День'}, {name: 'Неделя'}, {name: 'Месяц'}];
    this.colors = ['#A54500', '#FFB400', '#EB3235', '#CD58C1', '#8342D6', '#2E89EC',
      '#2ECAEC', '#63CBA5', '#98E000', '#E2DA1D', '#A6A6A6', '#263044'];
    this.customStatuses = [{ name: 'Устная бронь', color: '#2E89EC'}, { name: 'Временная бронь', color: '#A54500'}]
  }

  modalShow(id: string) {
    this.modalService.modalShow(id);
  }

  modalHide(id: string) {
    this.modalService.modalHide(id);
  }
}
