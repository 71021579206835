import { Component, OnInit } from '@angular/core';
import {AngularStylesModalService} from "../../../angular-styles/src/angular-styles/services/angular-styles-modal.service";

@Component({
  selector: 'app-stages',
  templateUrl: './stages.component.html',
  styleUrls: ['./stages.component.less']
})
export class StagesComponent implements OnInit {

  statuses = [];
  items = [];
  term = [];
  constructor(
    private modalService: AngularStylesModalService
  ) { }

  ngOnInit() {
    this.statuses = [
      { additionalStatus: null, name: 'Свободно'},
      { additionalStatus: null, name: 'Забронировано'},
      { additionalStatus: null, name: 'Продано'},
      { additionalStatus: 'Дополнительные статусы', name: 'Устная бронь'},
      { additionalStatus: 'Дополнительные статусы', name: 'Временная бронь'}
    ];
    this.items = [{name: 'Постоянно'}, {name: 'Временно'}];
    this.term = [{name: 'День'}, {name: 'Неделя'}, {name: 'Месяц'}];
  }

  modalShow(id: string) {
    this.modalService.modalShow(id);
  }

  modalHide(id: string) {
    this.modalService.modalHide(id);
  }

}
