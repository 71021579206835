<ng-container>
  <div class="form-group"
       [ngClass]="{'form-group_sm': size === 'small',
                   'form-group_md': size === 'medium',
                   'form-group_shift': shift === 'shift',
                   'form-group_shift-lg': shift === 'shift-lg',
                   'form-group_shift-double': shift === 'shift-double',
                   'form-group_shift-mixed': shift === 'shift-mixed',
                   'form-group_shift-lg-double': shift === 'shift-lg-double',
                   'form-group_inline': layout === 'inline',
                   'form-group_double': layout === 'double',
                   'form-group_double-labeled': layout === 'double-labeled',
                   'form-group_multi': layout === 'multi'}">
    <label class="control-label" *ngIf="label" [attr.for]="id">
      <ng-content select="[label]"></ng-content>
    </label>
    <ng-content select="[content]"></ng-content>
  </div>
</ng-container>
