import { TimeSelectorTerm, TimeSelectorTermInterface } from 'src/angular-styles/src/angular-styles/models/term.model';

export class ReserveSettings {
  id: number;
  crmPipelineId: string | number;
  confirmationStageId: string | number;
  reserveNotConfirmationId: string | number;
  taskEndBookedText: string;
  taskEndBookedTerm: TimeSelectorTerm;
  taskEndBookedEnabled: boolean;

  constructor(data?: ReserveSettingsInterface) {
    if (data) {
      this.id = Number(data.id);
      this.crmPipelineId = data.crmPipelineId;
      this.confirmationStageId = data.confirmationStageId;
      this.reserveNotConfirmationId = data.reserveNotConfirmationId;
      this.taskEndBookedText = data.taskEndBookedText;
      this.taskEndBookedTerm = data.taskEndBookedTerm && new TimeSelectorTerm(data.taskEndBookedTerm);
      this.taskEndBookedEnabled = Boolean(data.taskEndBookedEnabled);
    }
  }
}

export interface ReserveSettingsInterface {
  id: number;
  crmPipelineId: string | number;
  confirmationStageId: string | number;
  reserveNotConfirmationId: string | number;
  taskEndBookedText: string;
  taskEndBookedTerm: TimeSelectorTermInterface;
  taskEndBookedEnabled: boolean;
}
