import { OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, Validators } from '@angular/forms';

export abstract class BaseComponent implements OnDestroy {

  subscriptions: { [key: string]: any } = {};

  abstract ref: ChangeDetectorRef;

  addSubscriber(key: string, subscription: Subscription) {
    this.subscriptions[key] = subscription;
  }

  ngOnDestroy() {
    this.destroySubscriptions();
  }

  destroySubscriptions() {
    for (const key in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(key)) {
        this.subscriptions[key].unsubscribe();
      }
    }
  }


  baseRequiredControlChangeHandler(fg: FormGroup, controlAlias: string, control: boolean): void {
    if (control) {
      fg.controls[controlAlias].setValidators(Validators.required);
    } else {
      fg.controls[controlAlias].clearValidators();
    }

    fg.controls[controlAlias].updateValueAndValidity();
    fg.updateValueAndValidity();
    this.ref.markForCheck();
  }
}
