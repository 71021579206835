import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-accordeon',
  templateUrl: './accordeon.component.html',
  styleUrls: ['./accordeon.component.less']
})
export class AccordeonComponent implements OnInit {

  @Input() title: string;
  @Input() type: string;
  @Input() id: string;

  constructor() { }

  ngOnInit() {
  }

}
