import { CustomFieldViewModel } from './custom-field.view-model';
import { CustomStatus } from './custom-status.model';
import { ReserveSettings } from './reserve-settings.model';
import { QueueReserveSettings } from './queue.model';
import { PipelineViewModel } from './pipline.view-model';

export enum ModalName {
  modalDeleteBinding = 'modalDeleteBinding',
  customFieldModal = 'customFieldModal',
  queueModal = 'queueModal',
  customStatusesEditorModal = 'customStatusesEditorModal',
  modalReserveSettings = 'modalReserveSettings',
  modalDeleteStatus = 'modalDeleteStatus',
  statusModal = 'statusModal',
  modalDeleteQueue = 'modalDeleteQueue',
}

export enum FunnelActionEnum {

  /* REQUEST API */

  updatePipelines = 'updatePipelines',
  updateCustomStatuses = 'updateCustomStatuses',
  updateQueue = 'updateQueue',
  deleteCustomField = 'deleteCustomField',
  copyCustomField = 'copyCustomField',
  editCustomField = 'editCustomField',
  changePositionCustomField = 'changePositionCustomField',
  editCustomStatus = 'editCustomStatus',
  deleteCrmStatus = 'deleteCrmStatus',
  createCustomStatus = 'createCustomStatus',
  deleteCustomStatus = 'deleteCustomStatus',
  createCustomField = 'createCustomField',
  updateReserveSettings = 'updateReserveSettings',

  /* UI */

  openModal = 'openModal',
  closeModal = 'closeModal'
}

export interface PipelineInterface {
  id: string;
  name: string;
  crmStatuses: CrmStatusInterface[];
}

export interface CrmStatusInterface {
  name: string;
  id: any;
  color: string;
  unsorted: 0 | 1;
}


export class Pipeline {
  id: string;
  name: string;
  crmStatuses: CrmStatus[] = [];

  constructor(data?: PipelineInterface) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.crmStatuses = data.crmStatuses && data.crmStatuses.map(cs => new CrmStatus(cs));
    }
  }
}

export class CrmStatus {
  name: string;
  id: any;
  color: string;
  unsorted: 0 | 1;

  constructor(data?: CrmStatusInterface) {
    if (data) {
      this.name = data.name;
      this.id = data.id;
      this.color = data.color;
      this.unsorted = data.unsorted || 0;
    }
  }
}

export class FunnelAction {
  type: FunnelActionEnum;
  targetId?: any;
  customFieldViewModel?: CustomFieldViewModel;
  queue?: QueueReserveSettings;
  customStatus?: CustomStatus;
  deleteAction?: boolean;
  openModal?: ModalName;
  closeModal?: ModalName;
  reserveSettings?: ReserveSettings;
  pipeline?: Pipeline;
  pipelineViewModel?: PipelineViewModel;

  genCustomStatusWithoutProtected(): CustomStatus {
    const cs = new CustomStatus(this.customStatus);
    delete cs.isProtected;
    return cs;
  }

  genCustomStatusForEdit(): CustomStatus {
    const cs = this.genCustomStatusWithoutProtected();
    delete cs.baseStatus;
    return cs;
  }

  constructor(data: FunnelActionInterface) {
    if (data) {
      this.type = FunnelActionEnum[data.type];
      this.targetId = data.targetId;
      this.customFieldViewModel = data.customFieldViewModel;
      this.queue = data.queue;
      this.customStatus = data.customStatus;
      this.deleteAction = data.deleteAction;
      this.openModal = data.openModal && ModalName[data.openModal];
      this.closeModal = data.closeModal && ModalName[data.closeModal];
      this.reserveSettings = data.reserveSettings && data.reserveSettings;
      this.pipeline = data.pipeline;
      this.pipelineViewModel = data.pipelineViewModel;
    }
  }
}

export interface FunnelActionInterface {
  type: FunnelActionEnum;
  targetId?: any;
  customFieldViewModel?: CustomFieldViewModel;
  queue?: any;
  customStatus?: CustomStatus;
  deleteAction?: boolean;
  openModal?: ModalName | string;
  closeModal?: ModalName | string;
  reserveSettings?: ReserveSettings;
  pipeline?: Pipeline;
  pipelineViewModel?: PipelineViewModel;

}






