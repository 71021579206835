import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less']
})
export class NavigationComponent implements OnInit {

  aliasList: any;
  tabsList: any;
  pages: any;
  constructor() { }

  ngOnInit() {
    this.aliasList = [
      { name: 'step 1', enabled: true },
      { name: 'step 2', enabled: true },
      { name: 'step 3', enabled: true },
      { name: 'step 4', enabled: false }
    ];
    this.tabsList = [
      {name: 'Tab 1', alias: 'tab1'},
      {name: 'Tab 2', alias: 'tab2'}
    ];

    this.pages = ['#link1','#link2','#link3','#link4','#link5'];
  }

  menuClickHandler(e: any) {

  }

}
