import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.less']
})
export class FormGroupComponent implements OnInit {

  @Input() layout: string;
  @Input() label: boolean = true;
  @Input() size: string;
  @Input() shift: string;
  @Input() id: string;

  constructor() { }

  ngOnInit() {
  }

}
