import { Injectable } from '@angular/core';
import { State, StateInterface } from '../models/state.models';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private _state: State = new State();

  get state() {
    return this._state;
  }

  constructor() { }

  public setState(state: StateInterface) {
    this._state.setState(state);
    this.logger('set state', this.state);
  }

  private logger(action: string, data: any) {
    console.log(action, data);
  }
}
