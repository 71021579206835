import { Component, OnInit } from '@angular/core';
import { AngularStylesModalService } from 'src/angular-styles/src/angular-styles/services/angular-styles-modal.service';
import {AngularStylesLoaderService} from "../../../angular-styles/src/angular-styles/services/angular-styles-loader.service";

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.less']
})
export class ModalsComponent implements OnInit {

  items = [];
  statuses = [];
  term = [];
  stages = [];
  colors = [];

  showLoader: boolean = false;

  constructor(
    private modalService: AngularStylesModalService,
    private angularStylesLoaderService: AngularStylesLoaderService
  ) { }

  ngOnInit() {
    this.statuses = [{name: 'Свободно'}, {name: 'Забронировано'}, {name: 'Продано'}];
    this.items = [{name: 'Постоянно'}, {name: 'Временно'}];
    this.term = [{name: 'День'}, {name: 'Неделя'}, {name: 'Месяц'}];
    this.stages = [{name: 'Потребности выявлены'}, {name: 'Устная бронь'}, {name: 'Продано'}];
    this.colors = ['#A54500', '#FFB400', '#EB3235', '#CD58C1', '#8342D6', '#2E89EC',
      '#2ECAEC', '#63CBA5', '#98E000', '#E2DA1D', '#A6A6A6', '#263044'];
  }

  modalShow(id: string) {
    this.modalService.modalShow(id);
  }

  modalHide(id: string) {
    this.modalService.modalHide(id);
  }

  loaderShow() {
  }
}
