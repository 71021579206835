<h1 class="page-title">Colorpicker</h1>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[name]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td>Reqired</td>
  </tr>
  <tr>
    <td><b>[id]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td>Reqired</td>
  </tr>
  <tr>
    <td><b>[colors]</b></td>
    <td>array</td>
    <td>
      <b>["#A54500", "#FFB400", "#EB3235", "#CD58C1", "#8342D6", "#2E89EC", "#2ECAEC", "#63CBA5", "#98E000", "#E2DA1D", "#A6A6A6", "#263044"]</b>
    </td>
    <td>Reqired</td>
  </tr>
  <tr>
    <td colspan="4">Examples</td>
  </tr>
  <tr>
    <td colspan="2">
      <app-colorpicker [colors]="colors" [name]="'colorpicker'" [id]="'color'"></app-colorpicker>
    </td>
    <td colspan="2">
      <code>&lt;app-colorpicker [colors]="colors" [name]="'colorpicker'" [id]="'color'"&gt;&lt;/app-colorpicker&gt;</code>
    </td>
  </tr>
  </tbody>
</table>

