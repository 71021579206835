<h1 class="page-title">Таблицы - not a component</h1>

<div class="margin-bottom-10">class="table"</div>
<table class="table">
  <thead>
  <tr>
    <th>#</th>
    <th>First Name</th>
    <th>Last Name</th>
    <th>Username</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>1</td>
    <td>Mark</td>
    <td>Otto</td>
    <td>@mdo</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Jacob</td>
    <td>Thornton</td>
    <td>@fat</td>
  </tr>
  <tr>
    <td>3</td>
    <td>Larry</td>
    <td>the Bird</td>
    <td>@twitter</td>
  </tr>
  </tbody>
</table>


<div class="row">
  <div class="col-md-6 margin-bottom-20">
    <div class="margin-bottom-10">class="table table-striped table-hover"</div>
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th>#</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Username</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>Mark</td>
        <td>Otto</td>
        <td>@mdo</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Jacob</td>
        <td>Thornton</td>
        <td>@fat</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Larry</td>
        <td>the Bird</td>
        <td>@twitter</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-6 margin-bottom-20">
    <div class="margin-bottom-10">class="table table-striped table-bordered table-hover</div>
    <table class="table table-striped table-bordered table-hover">
      <thead>
      <tr>
        <th>#</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Username</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>Mark</td>
        <td>Otto</td>
        <td>@mdo</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Jacob</td>
        <td>Thornton</td>
        <td>@fat</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Larry</td>
        <td>the Bird</td>
        <td>@twitter</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<h3>Таблицы с кнопками</h3>

<div class="table-responsive">
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th>#</th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Username</th>
      <th class="table__col-actions">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>1</td>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
      <td class="table__col-actions">
        <div class="btns-block">
          <app-button [type]="'link'" [href]="'#'" [size]="'small'">Кнопка 1</app-button>
          <app-button [type]="'link'" [href]="'#'" [size]="'small'" [color]="'brand'">Кнопка 2</app-button>
        </div>
      </td>
    </tr>
    <tr>
      <td>2</td>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
      <td class="table__col-actions">
        <div class="btns-block">
          <app-button [type]="'link'" [href]="'#'" [size]="'small'">Кнопка 1</app-button>
          <app-button [type]="'link'" [href]="'#'" [size]="'small'" [color]="'brand'">Кнопка 2</app-button>
        </div>
      </td>
    </tr>
    <tr>
      <td>3</td>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td class="table__col-actions">
        <div class="btns-block">
          <app-button [type]="'link'" [href]="'#'" [size]="'small'">Кнопка 1</app-button>
          <app-button [type]="'link'" [href]="'#'" [size]="'small'" [color]="'brand'">Кнопка 2</app-button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="table-responsive">
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th>#</th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Username</th>
      <th class="table__col-actions">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>1</td>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
      <td class="table__col-actions">
        <div class="dropdown-wrap">
          <app-button [type]="'button'" [size]="'small'"><span>Действия</span><app-svg-icons [name]="'caret-down'" [customClass]="'svg-icon_caret'"></app-svg-icons></app-button>
        </div>
      </td>
    </tr>
    <tr>
      <td>2</td>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
      <td class="table__col-actions">
        <div class="dropdown-wrap">
          <app-button [type]="'button'" [size]="'small'"><span>Действия</span><app-svg-icons [name]="'caret-down'" [customClass]="'svg-icon_caret'"></app-svg-icons></app-button>
        </div>
      </td>
    </tr>
    <tr>
      <td>3</td>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td class="table__col-actions">
        <div class="dropdown-wrap" dropdown>
          <app-button [type]="'button'" [size]="'small'"><span>Действия</span><app-svg-icons [name]="'caret-down'" [customClass]="'svg-icon_caret'"></app-svg-icons></app-button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
