<h1 class="page-title">Селекты</h1>

<table class="table table-striped">
  <thead>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Values</th>
    <th>Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><b>[placeholder]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[appendTo]</b></td>
    <td>string</td>
    <td>any string value</td>
    <td>
      <p>By default ng-select appends dropdown to it's child.</p>
      <p>It can be fixed by appending dropdown to body or other parent element.</p>
    </td>
  </tr>
  <tr>
    <td><b>[multiple]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[searchable]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[disabled]</b></td>
    <td>boolean</td>
    <td>
      <ul class="list-styled">
        <li><b>true</b></li>
        <li><b>false</b> - default</li>
      </ul>
    </td>
    <td></td>
  </tr>
  <tr>
    <td><b>[items]</b></td>
    <td>array</td>
    <td>
      [{{ '{' }} name: 'option 1', groupName: 'some group' {{ '}' }}, {{ '{' }} name: 'option 2'{{ '}' }}, {{ '{' }} name: 'option 3'{{ '}' }}]
    </td>
    <td>Required</td>
  </tr>
  <tr>
    <td><b>[groupBy]</b></td>
    <td>string</td>
    <td>any key of [items]</td>
    <td></td>
  </tr>
  <tr>
    <td><b>[notFoundText]</b></td>
    <td>string</td>
    <td>any string</td>
    <td></td>
  </tr>
  <tr>
    <td colspan="4">Examples</td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="false"
                  [placeholder]="'Обычный селект - Выберите значение'"
                  [items]="items"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="false"
        [placeholder]="'Обычный селект - Выберите значение'"
        [items]="items"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="false"
                  [placeholder]="'с группировкой - Выберите значение'"
                  [items]="items"
                  [groupBy]="'groupName'"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="false"
        [placeholder]="'с группировкой - Выберите значение'"
        [items]="items"
        [groupBy]="'groupName'"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="false"
                  [placeholder]="'empty селект - Выберите значение'"
                  [notFoundText]="'Нет такого значения!'"
                  [items]="emptyItems"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="false"
        [placeholder]="'empty селект - Выберите значение'"
        [notFoundText]="'Нет такого значения!'"
        [items]="emptyItems"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="false"
                  [disabled]="true"
                  [placeholder]="'Disabled'"
                  [items]="items"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="false"
        [disabled]="true"
        [placeholder]="'Disabled'"
        [items]="items"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="false"
                  [searchable]="true"
                  [notFoundText]="'Нет такого значения!'"
                  [placeholder]="'Поиск'"
                  [items]="items"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="false"
        [searchable]="true"
        [notFoundText]="'Нет такого значения!'"
        [placeholder]="'Поиск'"
        [items]="items"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="true"
                  [placeholder]="'Мультиселект'"
                  [items]="items"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="true"
        [placeholder]="'Мультиселект'"
        [items]="items"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="true"
                  [placeholder]="'Мультиселект с группировкой'"
                  [groupBy]="'groupName'"
                  [items]="items"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="true"
        [placeholder]="'Мультиселект с группировкой'"
        [groupBy]="'groupName'"
        [items]="items"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="true"
                  [disabled]="true"
                  [placeholder]="'Disabled мультиселект'"
                  [items]="items"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="true"
        [disabled]="true"
        [placeholder]="'Disabled мультиселект'"
        [items]="items"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <app-select [multiple]="true"
                  [searchable]="true"
                  [placeholder]="'Мультиселект с поиском'"
                  [items]="items"></app-select>
    </td>
    <td colspan="2">
      <code>&lt;app-select [multiple]="true"
        [searchable]="true"
        [placeholder]="'Мультиселект с поиском'"
        [items]="items"&gt;&lt;/app-select&gt;</code>
    </td>
  </tr>
  <tr>
    <td colspan="4">Time-selector</td>
  </tr>
  <tr>
    <td colspan="2">
      <app-time-selector
        [(value)]="term"
      >
        Срок временной брони
      </app-time-selector>
    </td>
    <td colspan="2">
      <code>&lt;app-time-selector
        [(value)]="term"&gt;Срок временной брони&lt;/app-time-selector&gt;</code>
    </td>
  </tr>
  </tbody>
</table>
