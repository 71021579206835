import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class TranslateLoaderService {

  private get pathToJSON(): string {
    return '../../style-assets/i18n/';
  }

  contentHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });

  constructor(
    private http: HttpClient,
  ) { }

  public getTranslation(lang: string): Observable<any> {
    return new Observable(observer => {
      this.http.get(`${this.pathToJSON + lang}.json`).subscribe(
        (res: Response) => {
          observer.next(res);
          observer.complete();
        },
        err => {
          this.http.get(`${this.pathToJSON}ru.json`).subscribe((res: Response) => {
            observer.next(res);
            observer.complete();
          });
        });
    });
  }
}
