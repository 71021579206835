<h1 class="page-title">Statuses</h1>

<app-modal #modalEmptyStatus [id]="'modalEmptyStatus'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalEmptyStatus')">Управление статусами - нет статусов</a>
  <ng-container title>
    Управление статусами
    <app-popover [placement]="'bottom'">
      <app-svg-icons element [name]="'help-small'"></app-svg-icons>
      <article content>
        <p>Создавайте дополнительные статусы в рамках типовых статусов:</p>
        <ul class="list-status">
          <li class="list-status__item list-status__item_free">Свободно</li>
          <li class="list-status__item list-status__item_reserved">Забронировано</li>
          <li class="list-status__item list-status__item_sold">Продано</li>
        </ul>
        <p>Выберите для них уникальные цвета и названия.</p>
        <p>Данные статусы будут представлены менеджерам в виджете Profitbase для CRM в видах "Плитка", "Плитка+", "Список", "Этажи", при этом они не будут отображаться клиентам в смарт-каталоге Proftibase.</p>
      </article>
    </app-popover>
  </ng-container>
  <ng-container content>
    <article class="article">
    <p>У вас нет дополнительных статусов.</p>
    <p>
      <a href="javascript:;" (click)="modalHide('modalEmptyStatus')">+ Создать новый статус</a>
    </p>
    </article>
  </ng-container>
</app-modal>
<br>
<app-modal #modalNewStatus [id]="'modalNewStatus'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalNewStatus')">+ Создать новый статус</a>
  <ng-container title>
    Создание статуса
  </ng-container>
  <ng-container content>
    <app-form [layout]="'mixed'" [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group [layout]="'inline'">
        <ng-container label>тип</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'inline'">
        <ng-container label>название</ng-container>
        <ng-container content>
          <app-input [type]="'text'"
                     [placeholder]="'Введите название статуса'"
                     [label]="false"
          ></app-input>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'inline'">
        <ng-container label>цвет</ng-container>
        <ng-container content>
          <app-colorpicker [colors]="colors" [id]="'idColor'" [name]="'color1'"></app-colorpicker>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalNewStatus')">Создать</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalNewStatus')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
<app-modal #modalStatus [id]="'modalStatus'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalStatus')">Управление статусами - есть статусы</a>
  <ng-container title>
    Управление статусами
    <app-popover [placement]="'bottom'">
      <app-svg-icons element [name]="'help-small'"></app-svg-icons>
      <article content>
        <p>Создавайте дополнительные статусы в рамках типовых статусов:</p>
        <ul class="list-status">
          <li class="list-status__item list-status__item_free">Свободно</li>
          <li class="list-status__item list-status__item_reserved">Забронировано</li>
          <li class="list-status__item list-status__item_sold">Продано</li>
        </ul>
        <p>Выберите для них уникальные цвета и названия.</p>
        <p>Данные статусы будут представлены менеджерам в виджете Profitbase для CRM в видах "Плитка", "Плитка+", "Список", "Этажи", при этом они не будут отображаться клиентам в смарт-каталоге Proftibase.</p>
      </article>
    </app-popover>
  </ng-container>
  <ng-container content>
    <article class="article">
    <!--<app-status [items]="customStatuses"></app-status>-->кастомные статусы туть
    <p>
      <a href="javascript:;" (click)="modalHide('modalStatus')">+ Создать новый статус</a>
    </p>
    </article>
  </ng-container>
</app-modal>
<br>
<app-modal #modalEditStatus [id]="'modalEditStatus'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalEditStatus')">Редактирование статуса</a>
  <ng-container title>
    Редактирование статуса «Временная бронь»
  </ng-container>
  <ng-container content>
    <app-form [layout]="'mixed'" [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group [layout]="'inline'">
        <ng-container label>тип</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'inline'">
        <ng-container label>название</ng-container>
        <ng-container content>
          <app-input [type]="'text'"
                     [placeholder]="'Введите название статуса'"
                     [label]="false"
          ></app-input>
        </ng-container>
      </app-form-group>

      <app-form-group [layout]="'inline'">
        <ng-container label>цвет</ng-container>
        <ng-container content>
          <app-colorpicker [colors]="colors" [id]="'idColor2'" [name]="'color2'"></app-colorpicker>
        </ng-container>
      </app-form-group>

      <app-button [type]="'button'" (click)="modalHide('modalEditStatus')">Создать</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalEditStatus')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
<br>
<app-modal #modalDeleteStatus [id]="'modalDeleteStatus'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalDeleteStatus')">Удаление статуса</a>
  <ng-container title>
    Удаление статуса
  </ng-container>
  <ng-container content>
    <article class="article">
      <p>Данный статус используется для следующих этапов Воронки продаж:</p>
      <ul class="list-styled">
        <li>Контакт</li>
        <li>Встреча</li>
      </ul>
      <p>Вы уверены, что хотите удалить статус «Временная бронь»?</p>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modalDeleteStatus')">Удалить</app-button>
    <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalDeleteStatus')">Отмена</app-button>
  </ng-container>
</app-modal>
<br>
<app-modal #modalConfirmStatus [id]="'modalConfirmStatus'" [title]="true">
  <a href="javascript:;" element (click)="modalShow('modalConfirmStatus')">Редактирование статуса</a>
  <ng-container title>
    Редактирование статуса
  </ng-container>
  <ng-container content>
    <article class="article">
      <p>Изменения вступят в силу для всех этапов со статусом «Временная бронь»:</p>
      <ul class="list-styled">
        <li>Контакт</li>
        <li>Встреча</li>
      </ul>
    </article>
    <app-button [type]="'button'" (click)="modalHide('modalConfirmStatus')">Применить</app-button>
    <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalConfirmStatus')">Отмена</app-button>
  </ng-container>
</app-modal>
<br>
<app-modal #modalReserveSettings [id]="'modalReserveSettings'" [title]="true">
  <app-button [type]="'button'" [size]="'small'" element (click)="modalShow('modalReserveSettings')">Настройка бронирования</app-button>
  <ng-container title>Настройка бронирования</ng-container>
  <ng-container content>
    <app-form [action]="'#'" [method]="'post'" [id]="'form-id'">

      <app-form-group>
        <ng-container label>
          Для подтверждения брони переводить сделку на этап
          <app-popover [placement]="'right'" [content]="'Для менеджеров без права бронирования, сделки переносятся на выбранный этап для подтверждения ответственным менеджером, который выбран в разделе Пользователи и права.'">
            <app-svg-icons element [name]="'help-small'"></app-svg-icons>
          </app-popover>
        </ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group>
        <ng-container label>Выберите этап на который автоматически перейдет сделка, если временная бронь не подтвердится</ng-container>
        <ng-container content>
          <app-select [multiple]="false"
                      [placeholder]="'Выберите статус'"
                      [items]="statuses"
                      [groupBy]="'additionalStatus'"></app-select>
        </ng-container>
      </app-form-group>

      <app-form-group [size]="'medium'">
        <ng-container label>
          <app-checkbox [type]="'checkbox'" [layout]="'single'" [id]="'id'">
            <ng-container label>Задача менеджеру при окончании брони</ng-container>
          </app-checkbox>
        </ng-container>
        <ng-container content>
          <app-textarea
                        [rows]="2"
                        [placeholder]="'Помещение &quot;%address%&quot; освободилось. Забронируйте помещение. Если вы не поставите бронь в течение суток, то сделка будет удалена из очереди, а задача переставлена на следующего в очереди менеджера.'"
                        [label]="false"
          ></app-textarea>
        </ng-container>
      </app-form-group>

      <app-time-selector [(value)]="term">Уведомить за</app-time-selector>

      <app-button [type]="'button'" (click)="modalHide('modalIpotekaTemp')">Сохранить</app-button>
      <app-button [type]="'button'" [color]="'transparent'" (click)="modalHide('modalIpotekaTemp')">Отмена</app-button>

    </app-form>
  </ng-container>
</app-modal>
